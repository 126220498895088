import React, {useEffect, useState} from "react";
import axios from 'utility/customAxios';
import {useSelector} from "react-redux";
import timerange from "../../store/reducers/timerange";
import SummaryTable from "./SummaryTable";
import PodsTable from "./PodsTable";
import {K8sPodDetails} from "./model";
import {ChartType, MetoroMetricsChart, MetricType} from "../../pages/MetricsTest";
import {Tooltip, TooltipContent, TooltipTrigger} from "../ui/tooltip";
import {InfoIcon} from "lucide-react";
import {useSearchParams} from "react-router-dom";
import {TimeRange} from "../../types/time";
import {useDebouncedCallback} from "use-debounce";
import {RateFunctionType} from "../Dashboarding/widgets/MetricSelector";
import {cn} from "../ui/lib/utils";


export interface K8sSummaryResponseV2 {
    k8sResourceSummary: K8sResourceSummary[];
}

export interface K8sResourceSummary {
    environment: string;
    kind: string;
    resourceYaml: string;
}

export interface GetPodsResponse {
    pods: K8sPodDetails[]
    actualLength: number
    isResultLimited: boolean
}

// type GetPodsSummary struct {
//     States []PodSummary `json:"states"`
// }
//
// type PodSummary struct {
//     Bucket     time.Time `json:"bucket"`
//     Running    uint64    `json:"running"`
//     Waiting    uint64    `json:"waiting"`
//     Terminated uint64    `json:"terminated"`
// }

interface GetPodsCountResponse {
    summaries: PodSummary[]
}

interface PodSummary {
    bucket: number
    running: number
    waiting: number
    terminated: number
}

export interface PodState {
    startTime: number;
    endTime: number;
    timestamp: number;
    phase: string;
    reason: string;
    exitCode: string;
    version: string;
}

interface KubernetesViewProps {
    serviceName: string;
}

export function MetricsPanel(props: {
    title: string
    tooltipText: string
    serviceName: string
    groupBy?: string[]
    groupByForNetworkCharts?: string[]
    filters?: Map<string, string[]>
    excludeFilters?: Map<string, string[]>
}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [environment, setEnvironment] = useState<string[]>([]);
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [filters, setFilters] = useState<Map<string, string[]>>(
        props.filters || new Map<string, string[]>()
    );
    const groupByToUse = (props.groupBy && props.groupBy.length > 0) ? props.groupBy : ["container_id"];
    const groupByForNetworkCharts = (props.groupByForNetworkCharts && props.groupByForNetworkCharts.length > 0) ? props.groupByForNetworkCharts : ["pod_name"];


    useEffect(() => {
        let newEnv = searchParams.get("environment")
        if (JSON.stringify(environment) === JSON.stringify([newEnv])) {
            return
        }
        if (newEnv) {
            setEnvironment([newEnv]);
        } else {
            setEnvironment([]);
        }
    }, [searchParams]);

    useEffect(() => {
        // Short circuit if the filter is already set
        if (filters.get("service_name")?.[0] === props.serviceName && JSON.stringify(environment) === JSON.stringify(filters.get("environment"))) {
            return
        }
        console.log("Setting filters")

        let filtersToUse = new Map<string, string[]>(filters);
        filtersToUse.set("service_name", [props.serviceName]);
        filtersToUse.set("environment", [...environment]);
        setFilters(filtersToUse);

    }, [props.serviceName, props.filters, environment]);

    const [startTime, setStartTime] = useState<number>(0);
    const [endTime, setEndTime] = useState<number>(0);
    useEffect(() => {
        setStartTime(Math.floor((timeRange.getStartEnd())[0].getTime() / 1000));
        setEndTime(Math.floor((timeRange.getStartEnd())[1].getTime() / 1000));
    }, [timeRange]);

    return <div className={"bg-backgroundmedium border p-4 relative"}>
        <Tooltip>
            <TooltipTrigger>
                <div className={"flex gap-2 mb-4"}>
                    <div className={"text-lg text-textmedium flex flex-col justify-center"}>
                        {props.title}
                    </div>
                    <div className={"flex flex-col justify-center"}>
                        <InfoIcon className={"h-4 w-4 text-primary"}/>
                    </div>
                </div>
            </TooltipTrigger>
            <TooltipContent className={"border bg-backgroundmedium text-textmedium p-2"}>
                {props.tooltipText}
            </TooltipContent>
        </Tooltip>
        <div className={"grid-cols-2 grid gap-4 rounded text-textlight overflow-y-auto relative"}>
            <MetoroMetricsChart
                hideOnNoData={false}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricName={"container_resources_cpu_usage_seconds_total"}
                functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                aggregation={"max"}
                type={ChartType.Line}
                title={`CPU Usage - Seconds`}
                splits={groupByToUse}
                filters={filters}
                excludeFilters={props.excludeFilters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
            />
            <MetoroMetricsChart
                hideOnNoData={true}
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                startTime={startTime}
                endTime={endTime}
                metricName={"container_resources_cpu_limit_cores"}
                aggregation={"max"}
                type={ChartType.Line}
                title={`CPU Limit - Cores`}
                splits={groupByToUse}
                filters={filters}
                excludeFilters={props.excludeFilters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
                functions={[]}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={false}
                startTime={startTime}
                endTime={endTime}
                metricName={"container_resources_memory_rss_bytes"}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Memory Usage - Bytes`}
                splits={groupByToUse}
                filters={filters}
                excludeFilters={props.excludeFilters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
                functions={[]}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={true}
                startTime={startTime}
                endTime={endTime}
                metricName={"container_resources_memory_limit_bytes"}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Memory Limit - Bytes`}
                splits={groupByToUse}
                filters={filters}
                excludeFilters={props.excludeFilters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
                functions={[]}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={true}
                startTime={startTime}
                endTime={endTime}
                metricName={"container_resources_disk_read_bytes_total"}
                functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Disk Bytes Read`}
                splits={groupByToUse}
                filters={filters}
                excludeFilters={props.excludeFilters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={true}
                startTime={startTime}
                endTime={endTime}
                metricName={"container_resources_disk_written_bytes_total"}
                functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Disk Bytes Written`}
                splits={groupByToUse}
                filters={filters}
                excludeFilters={props.excludeFilters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={true}
                startTime={startTime}
                endTime={endTime}
                metricName={"container_resources_disk_used_bytes"}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Disk Usage`}
                splits={groupByToUse}
                filters={filters}
                excludeFilters={props.excludeFilters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
                functions={[]}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={false}
                startTime={startTime}
                endTime={endTime}
                metricName={"container_net_tcp_active_connections"}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Open TCP Connections`}
                splits={groupByToUse}
                filters={filters}
                excludeFilters={props.excludeFilters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
                functions={[]}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={false}
                startTime={startTime}
                endTime={endTime}
                metricName={"container_net_bytes_received_total"}
                functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Network Bytes Received`}
                splits={groupByForNetworkCharts}
                filters={filters}
                excludeFilters={props.excludeFilters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
            />
            <MetoroMetricsChart
                className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                hideOnNoData={false}
                startTime={startTime}
                endTime={endTime}
                metricName={"container_net_bytes_sent_total"}
                functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                aggregation={"max"}
                type={ChartType.Line}
                title={`Network Bytes Sent`}
                splits={groupByForNetworkCharts}
                filters={filters}
                excludeFilters={props.excludeFilters}
                styling={{borderless: true}}
                metricType={MetricType.Metric}
            />
        </div>
    </div>
}

function updatePodsCount(timeRange: TimeRange, props: {
                             serviceName: string
                         }, environments: string[], setPodLifecycle: (value: (((prevState: (PodSummary[] | undefined)) => (PodSummary[] | undefined)) | PodSummary[] | undefined)) => void,
                         abortController: AbortController,
                         setAbortController: (value: React.SetStateAction<AbortController>) => void
) {
    try {
        abortController.abort("Subsequent request made");
        let newAbortController = new AbortController();
        setAbortController(newAbortController);
        const startEnd = timeRange.getStartEnd();
        axios.post("/api/v1/k8s/pods/count", {
                "serviceName": props.serviceName,
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "environments": environments
            },
            {
                signal: newAbortController.signal
            }
        ).then((response) => {
            const respData = response.data as GetPodsCountResponse
            setPodLifecycle(respData.summaries);
        }).catch((e) => {
            console.error(e);
        })
    } catch (e) {
        console.error(e);
    }
}

async function getPods(timeRange: TimeRange,
                       props: { serviceName: string },
                       environments: string[],
                       setPods: (value: (((prevState: GetPodsResponse | undefined) => GetPodsResponse) | GetPodsResponse | undefined)) => void,
                       abortController: AbortController,
                       setAbortController: (value: React.SetStateAction<AbortController>) => void
) {
    try {
        abortController.abort("Subsequent request made");
        let newAbortController = new AbortController();
        setAbortController(newAbortController);
        const startEnd = timeRange.getStartEnd();
        const response = await axios.post("/api/v1/k8s/pods", {
                "serviceName": props.serviceName,
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "environments": environments
            },
            {
                signal: newAbortController.signal
            }
        )
        const responseData = response.data as GetPodsResponse;
        setPods(responseData);
    } catch (e) {
        console.error(e);
    }
}

async function updateK8sSummaryRequest(environments: string[], timeRange: TimeRange, props: {
                                           serviceName: string
                                       }, setK8sSummaryResponse: (value: (((prevState: K8sSummaryResponseV2 | undefined) => K8sSummaryResponseV2) | K8sSummaryResponseV2 | undefined)) => void,
                                       abortController: AbortController,
                                       setAbortController: (value: React.SetStateAction<AbortController>) => void
) {
    try {
        abortController.abort("Subsequent request made");
        let newAbortController = new AbortController();
        setAbortController(newAbortController);
        const startEnd = timeRange.getStartEnd();
        const response = await axios.post("/api/v1/k8s/summary", {
                "serviceName": props.serviceName,
                "startTime": Math.floor(startEnd[0].getTime() / 1000),
                "endTime": Math.floor(startEnd[1].getTime() / 1000),
                "environments": environments
            },
            {
                signal: newAbortController.signal
            }
        )
        const responseData = response.data as K8sSummaryResponseV2;
        setK8sSummaryResponse(responseData);
    } catch (e) {
        console.error(e);
    }
}

function PodCounts(props: { podCounts: PodSummary[] }) {
    return <div className={"mt-8 w-full h-[100px] bg-background-secondary"}>
        <div className={"flex gap-1 justify-start w-full"}>
            {props.podCounts.map((podCount, index) => {
                return <Tooltip delayDuration={0}>
                    <TooltipTrigger>
                        <div
                            className={cn("flex w-[24px] h-[48px] hover:bg-secondarytransparent", getColour(podCount), getHoverColor(podCount))}/>
                    </TooltipTrigger>
                    <TooltipContent side={"bottom"}>
                        <div className={"flex flex-col gap-y-1"}>
                            <div
                                className={"font-semibold"}>{new Date(podCount.bucket * 1000).toLocaleString()}</div>
                            {podCount.running > 0 &&
                                <div className={"flex gap-1 items-center"}>
                                    <div className={"h-[11px] w-[11px] bg-secondary"}></div>
                                    <div>Pods Running:</div>
                                    <div className={"font-semibold text-xs"}>{podCount.running}</div>
                                </div>
                            }
                            {podCount.waiting > 0 &&
                                <div className={"flex gap-1 items-center"}>
                                    <div className={"h-[11px] w-[11px] bg-amber-500"}></div>
                                    <div>Pods Waiting:</div>
                                    <div className={"font-semibold text-xs"}>{podCount.waiting}</div>
                                </div>
                            }
                            {
                                podCount.terminated > 0 &&
                                <div className={"flex gap-1 items-center"}>
                                    <div className={"h-[11px] w-[11px] bg-red-500"}></div>
                                    <div>Pods Terminated:</div>
                                    <div className={"font-semibold text-xs"}>{podCount.terminated}</div>
                                </div>
                            }
                        </div>
                    </TooltipContent>
                </Tooltip>
            })}
        </div>
    </div>
}

function getColour(podCount: PodSummary) {
    if (podCount.terminated > 0) {
        return "bg-red-500"
    } else if (podCount.waiting > 0) {
        return "bg-amber-500"
    } else if (podCount.running > 0) {
        return "bg-secondary"
    }
    return "bg-secondary"
}

function getHoverColor(podCount: PodSummary) {
    if (podCount.terminated > 0) {
        return "hover:bg-gray-600"
    } else if (podCount.waiting > 0) {
        return "hover:bg-amber-600"
    } else if (podCount.running > 0) {
        return "hover:bg-secondarytransparent"
    }
    return "hover:bg-gray-800"
}

export const KubernetesView: React.FC<KubernetesViewProps> = (props: {
    serviceName: string,
}) => {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [searchParams, setSearchParams] = useSearchParams();
    const [environments, setEnvironments] = useState<string[]>([]);
    const [allEnvironments, setAllEnvironments] = useState<string[]>([]);
    const [filtersForMetrics, setFiltersForMetrics] = useState<Map<string, string[]>>(new Map<string, string[]>());

    // For the pods table
    const [currentRunningPods, setCurrentRunningPods] = React.useState<GetPodsResponse>();
    const debouncedGetPods = useDebouncedCallback(getPods, 10);
    const [getPodsAbortController, setGetPodsAbortController] = useState(new AbortController());


    // For the Pod Events section.
    const [podSummaryCounts, setPodSummaryCounts] = React.useState<PodSummary[]>();
    const debouncedUpdatePodsSummaryCounts = useDebouncedCallback(updatePodsCount, 10);
    const [podSummaryAbortController, setPodSummaryAbortController] = useState(new AbortController());

    // For the summary table
    const [k8sSummary, setK8sSummary] = useState<K8sSummaryResponseV2>();
    const debouncedUpdateK8sSummaryRequest = useDebouncedCallback(updateK8sSummaryRequest, 10);
    const [k8sSummaryAbortController, setK8sSummaryAbortController] = useState(new AbortController());

    useEffect(() => {
        axios.get("/api/v1/environments").then((response) => {
            setAllEnvironments(response.data.environments);
        })
    }, [])

    useEffect(() => {
        let environment = searchParams.get("environment") || "";
        if (JSON.stringify(environments) === JSON.stringify([environments]) || (environment === "" && environments.length === 0)) {
            return
        }
        if (environment !== "") {
            if (environments.length === 1 && environments[0] === environment) {
                return
            }
            setEnvironments([environment])
        } else {
            setEnvironments([])
        }
    }, [searchParams, allEnvironments])

    useEffect(() => {
        let filtersToSet = filtersForMetrics;
        filtersToSet.set("environment", environments);
        setFiltersForMetrics(filtersToSet)
    }, [environments]);

    useEffect(() => {
        debouncedGetPods(timeRange, props, environments, setCurrentRunningPods, getPodsAbortController, setGetPodsAbortController);
    }, [timeRange, environments]);


    useEffect(() => {
        debouncedUpdateK8sSummaryRequest(environments, timeRange, props, setK8sSummary, k8sSummaryAbortController, setK8sSummaryAbortController);
    }, [timeRange, environments]);


    useEffect(() => {
        debouncedUpdatePodsSummaryCounts(timeRange, props, environments, setPodSummaryCounts, podSummaryAbortController, setPodSummaryAbortController);
    }, [timeRange, environments]);

    // TODO: Do this in the backend.
    // function orderPodsByCreationTime(podStates: Map<string, PodState[]>) {
    //     let creationTimeArray: number[] = [];
    //
    //     // Iterate through podNameToStatusMap and store the latest timestamp of the states in creationTimeArray
    //     podStates.forEach((states, podName) => {
    //         const latestTimestamp = states[states.length - 1].timestamp;
    //         creationTimeArray.push(latestTimestamp);
    //     })
    //     // Sort the creationTimeArray in descending order
    //     creationTimeArray.sort((a, b) => b - a);
    //
    //     // Create the sortedCreationTimeMap that will store the sorted pods by their latest timestamp
    //     let sortedCreationTimeMap: Map<string, PodState[]> = new Map<string, PodState[]>();
    //
    //     // Iterate through the sorted creationTimeArray to map pod names to their states in order
    //     creationTimeArray.forEach((creationTime) => {
    //         for (let [podName, states] of podStates) {
    //             if (states[states.length - 1].timestamp === creationTime) {
    //                 if (!sortedCreationTimeMap.get(podName)) { // If the key does not exist
    //                     sortedCreationTimeMap.set(podName, states);
    //                     break;
    //                 }
    //             }
    //         }
    //     })
    //     return sortedCreationTimeMap
    // }

    return <div className={"flex flex-col grow shrink gap-4 min-w-0 min-h-0 overflow-y-auto"}>
        <div className={"grid grid-cols-2 grid-rows-1 h-[256px] gap-x-4 flex-none"}>
            <SummaryTable summary={k8sSummary}
                          pods={currentRunningPods}/>
            <div className={"text-textmedium border relative bg-backgroundmedium p-4 overflow-y-auto"}>
                <div className={"text-lg font-semibold pb-2"}>
                    Pods Status
                </div>
                {podSummaryCounts && podSummaryCounts.length > 0 &&
                    <PodCounts podCounts={podSummaryCounts}/>}
            </div>
        </div>
        <PodsTable serviceName={props.serviceName}
                   isAggregated={environments.length > 1}
                   podResponse={currentRunningPods}/>
        <MetricsPanel
            filters={filtersForMetrics}
            serviceName={props.serviceName}
            title={"Aggregated Pod Metrics"}
            tooltipText={"This panel shows aggregated metrics for all containers in the selected service. For more detailed\n" +
                "                information, drill into individual pods."}
        />
    </div>

}
