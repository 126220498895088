import {Bar} from "react-chartjs-2";
import React from "react";
import {ChartOptions,} from "chart.js";
import {ChartData} from "chart.js/dist/types";
import {Span} from "./TraceDetails";
import {shortEnglishHumanizer} from "../../ServicePanel/ServicePanel";
import {ServerIcon} from "lucide-react";
import TagContainer from "../Tags/TagContainer";

const TOP_LEVEL_PARENT_SPAN_ID = "0000000000000000";
export const options: ChartOptions<"bar"> = {
    indexAxis: 'y',
    responsive: true,
    elements: {
        bar: {
            hoverBorderColor: "#3793FF",
            hoverBackgroundColor: "rgba(55, 147, 255, 0.1)",
        }
    },
    plugins: {
        datalabels: {
            color: '#EBF1F7',
            display: (context) => {
                return context.dataset.data[context.dataIndex] !== null
                    ? "auto"
                    : false;
            }
        },
        legend: {
            display: false,
        },
        tooltip: {
            intersect: false,
            borderColor: "#334670",
            borderWidth: 1,
            cornerRadius: 2,
            backgroundColor: "#1D273F",
            titleColor: "#C6D3E2",
            bodyColor: "#C6D3E2",
            footerColor: "#C6D3E2",
            footerAlign: "left",
            titleAlign: "left",
            bodyAlign: "left",
            displayColors: false,
            padding: 8,
            bodyFont: {
                weight: 'bold'
            },
            // callbacks: {
            //     title: (tooltipItem) => {
            //         const passedData: any = tooltipItem[0].dataset.data[0];
            //         const spanAttr: Span = passedData[passedData.length - 1];
            //         return `Duration: ${shortEnglishHumanizer(spanAttr.duration / 1_000_000)}`
            //     },
            //     labelTextColor: function (context) {
            //         return '#C6D3E2'
            //     },
            //     label: tooltipItem => {
            //         const passedData: any = tooltipItem.dataset.data[0];
            //         const spanAttr: Span = passedData[passedData.length - 1];
            //         return `Server: ${spanAttr.displayServiceName}`
            //     },
            //     beforeBody: (tooltipItem) => {
            //         const passedData: any = tooltipItem[0].dataset.data[0];
            //         const spanAttr: Span = passedData[passedData.length - 1];
            //         return `Client: ${spanAttr.displayClientName}`
            //     },
            //     footer: (tooltipItem) => {
            //         const passedData: any = tooltipItem[0].dataset.data[0];
            //         const spanAttr: Span = passedData[passedData.length - 1];
            //         return `Operation: ${spanAttr.spanAttributes["http.method"]} - ${extractAPIPath(spanAttr.spanAttributes["http.url"])}`
            //     },
            // }
        },
    },

    scales: {
        y: {
            display: false,
            grid: {
                display: false
            },
            ticks: {
                display: false
            },
            stacked: true,
        },
        x: {
            display: true,
            grid: {
                display: false
            },
            stacked: true,
            position: 'top',
        }
    }
}

export function TraceChart(props: {
    filter: Map<string, string[]>,
    setFilter: (filter: Map<string, string[]>) => void,
    excludeFilter: Map<string, string[]>,
    setExcludeFilter: (filter: Map<string, string[]>) => void,
    spans: Span[]
}) {
    const [showSpanDetails, setShowSpanDetails] = React.useState(false);
    const [spanDetails, setSpanDetails] = React.useState<Span | null>(null);
    if (props.spans.length === 0) {
        return <div className={"m-4 text-textmedium"}>No spans to show</div>
    }

    const processedSpans = processSpans(props.spans);
    const spansWithRelativeStartTimes = addRelativeStartTimes(processedSpans.spanMap);
    const levels = processedSpans.spanLevels;

    const finalDataset: any = []
    for (const spanId in spansWithRelativeStartTimes) {
        const span = spansWithRelativeStartTimes[spanId];
        let start = span.relativeStartTime!
        let end = span.relativeStartTime! + span.duration / 1000_000
        let data = new Array(levels).fill(null)
        data[span.level - 1] = [start, end, span]
        finalDataset.push({
            label: `Duration: ${shortEnglishHumanizer(span.duration / 1_000_000)}`,
            datalabels: {
                formatter: () => `${span.spanAttributes["server.service.name"]} - ${span.spanAttributes["http.status_code"] ? span.spanAttributes["http.status_code"].toUpperCase() : span.spanAttributes["http.status_code"]} - ${span.spanAttributes["http.path"]}`
            },
            data: data,
            fill: false,
            borderSkipped: null,
            barPercentage: 1,
            categoryPercentage: 0.80,
            backgroundColor: [
                // 'rgba(54, 162, 235, 0.2)', // Still a nice color so i will keep it here. We can integrate more pairings later.
                'rgba(55, 147, 255, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
            ],
            borderColor: [
                // 'rgb(54, 162, 235)', // Still a nice color so i will keep it here. We can integrate more pairings later.
                'rgb(55, 147, 255)',
                'rgb(75, 192, 192)',
                'rgb(153, 102, 255)',
            ],
            borderWidth: 1.5,
        })
    }

    const data: ChartData<"bar"> = {
        labels: processNumOfLabels(levels),
        datasets: finalDataset
    };

    const handleHover = (event: any, chartElement: any) => {
        const chart = event.chart;
        if (chartElement.length) {
            event.native.target.style.cursor = 'pointer';
        } else {
            event.native.target.style.cursor = 'default';
        }
    };

    const handleClick = (event: any) => {
        const chart = event.chart;
        const activePoints = chart.getElementsAtEventForMode(event.native, 'nearest', {intersect: true}, true);

        if (activePoints.length) {
            const firstPoint = activePoints[0];
            const label = chart.data.labels[firstPoint.index];
            const value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
            setSpanDetails(value[2])
            setShowSpanDetails(true)
        }
    };
    const combinedAttributes = {
        ...spanDetails?.spanAttributes,
        ...spanDetails?.resourceAttributes,
        ...{clientName: spanDetails?.displayClientName},
        ...{serverName: spanDetails?.displayServiceName}
    }
    return <div className={"flex-col"}>
        <Bar data={data} options={{...options, onClick: handleClick, onHover: handleHover}}/>
        {showSpanDetails && spanDetails &&
            <div className={"flex-row"}>
                <TagContainer
                    filter={props.filter}
                    setFilter={props.setFilter}
                    excludeFilter={props.excludeFilter}
                    setExcludeFilter={props.setExcludeFilter}
                    setWidth={"w-full"}
                    title={"Span Attributes"}
                    attributes={combinedAttributes}
                    icon={<ServerIcon className={"text-textdark"}/>}
                />
            </div>
        }
    </div>


}

// This is a hack, creating many labels/groups such that the bars do not resize. We can alternatively reduce the area for single labels in y axis.
function processNumOfLabels(inputLevelCount: number): number[] {
    const currWindowHeight = window.innerHeight
    const windowHeightToLabelsRatioToUse = 1328 / (20);
    const numOfLabels = Math.floor(currWindowHeight / windowHeightToLabelsRatioToUse)
    const outputLevelCount = Math.max(numOfLabels, inputLevelCount);

    return Array.from({length: outputLevelCount}, (_, i) => i + 1)
}

interface SpanWithLevel extends Span {
    level: number;
    relativeStartTime?: number;
}

type SpanMap = { [spanId: string]: SpanWithLevel };

function processSpans(spans: Span[]): { spanMap: { [p: string]: SpanWithLevel }; spanLevels: number } {
    // Create a map to hold spans by their spanId for easy lookup
    const spanMap: { [key: string]: SpanWithLevel } = {};

    // Initialize the spanMap with spans and default level -1
    spans.forEach(span => {
        spanMap[span.spanId] = {...span, level: -1};
    });

    // Recursive function to set levels
    function setLevel(span: SpanWithLevel, currentLevel: number): void {
        span.level = currentLevel;
        for (const s of spans) {
            if (s.parentSpanId === span.spanId) {
                setLevel(spanMap[s.spanId], currentLevel + 1);
            }
        }
    }

    // Set levels starting from the root spans (parentSpanId === "0000000000000000")
    spans.forEach(span => {
        if (span.parentSpanId === TOP_LEVEL_PARENT_SPAN_ID || span.parentSpanId === "\0\0\0\0\0\0\0\0" || span.parentSpanId === "") {
            setLevel(spanMap[span.spanId], 1);
        }
    });

    // Find the tree depth aka levelCount.
    let levelCount = 0;
    for (const spanId in spanMap) {
        if (spanMap[spanId].level > levelCount) {
            levelCount = spanMap[spanId].level;
        }
    }

    return {spanMap: spanMap, spanLevels: levelCount};
}

function addRelativeStartTimes(spanMap: SpanMap): SpanMap {
    const spansByLevel: { [level: number]: SpanWithLevel[] } = {};

    // Organize spans by their levels
    for (const spanId in spanMap) {
        const span = spanMap[spanId];
        if (!spansByLevel[span.level]) {
            spansByLevel[span.level] = [];
        }
        spansByLevel[span.level].push(span);
    }

    // Sort spans by their start time within each level
    for (const level in spansByLevel) {
        spansByLevel[level].sort((a, b) => a.time - b.time);
    }

    // Calculate relative start times
    for (const level in spansByLevel) {
        let previousEndTime = 0;
        spansByLevel[level].forEach(span => {
            span.relativeStartTime = span.time - previousEndTime;
            previousEndTime = span.time + span.duration / 1000;
        });
    }
    // Normalize the relative start times such that the first one starts at 0.
    if (spansByLevel[1] !== undefined && spansByLevel[1].length > 0) {
        const firstSpanRelativeStartTime = spansByLevel[1][0].relativeStartTime;
        if (firstSpanRelativeStartTime !== undefined) {
            for (const level in spansByLevel) {
                spansByLevel[level].forEach(span => {
                    if (span.relativeStartTime !== undefined && span.relativeStartTime > 0) {
                        span.relativeStartTime = span.relativeStartTime - firstSpanRelativeStartTime;
                    }
                });
            }
        }
    }
    return spanMap;
}