import React, {useEffect, useState} from "react";
import {cn, usePreserveQueryParamsNavigate} from "../components/ui/lib/utils";
import {
    AudioLinesIcon,
    BellIcon,
    BookIcon,
    Boxes,
    DollarSignIcon,
    GlobeIcon,
    LogOutIcon,
    Telescope
} from "lucide-react";
import {DateTimePickerRange} from "../components/ui/DateTimeSelector";
import {useLocation} from "react-router-dom";
import {Tooltip, TooltipContent, TooltipProvider, TooltipTrigger} from "../components/ui/tooltip";
import useAuth from "../hooks/useAuth";
import {TbLayoutDashboard} from "react-icons/tb";
import {IoMdSettings} from "react-icons/io";
import logoIcon from 'assets/images/logoColour.png';
import {FaMapMarked} from "react-icons/fa";
import {ClusterSelector} from "../components/ClusterSelector";
import {ExpandedChartDialog} from "../components/ExpandedChartDialog";
import {getCurrentProductFromApi} from "./Billing";
import {useCookies} from "react-cookie";


const BaseViewTest = () => {
    return <BaseView title={"Services"}/>;
}

function IconWithBorder(props: { highlighted?: boolean, icon: React.ReactNode }) {
    return (
        <div
            className={cn("hover:cursor-pointer hover:border-primary h-8 w-8 border rounded justify-center flex items-center text-primary stroke-2 stroke-primary", props.highlighted ? "border-secondary bg-secondarytransparenter text-secondary stroke-secondary" : "")}>
            {props.icon}
        </div>
    )
}

function CornerSquare() {
    return (
        <div className={"h-12 w-12 border-b justify-center items-center flex"}>
            <img src={logoIcon} alt="Metoro" className="w-8 h-8"/>
        </div>
    )
}

function LeftBar() {
    const location = useLocation();
    const navigate = usePreserveQueryParamsNavigate();
    const jwtContext = useAuth();
    const { search } = useLocation();


    const icons = [
        {
            "tooltip": "Dashboards",
            "location": "/dashboards",
            "pageNames": ["/dashboard", "/dashboards"],
            "icon": <TbLayoutDashboard className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Alerts",
            "location": "/alerts",
            "pageNames": ["/alerts", "/new-alert", "/alert"],
            "icon": <BellIcon className={"w-4 h-4"}/>
        },
        {"tooltip": "Logs", "pageNames": ["/logs"], "location": "/logs", "icon": <BookIcon className={"w-4 h-4"}/>},
        {
            "tooltip": "Traces",
            "pageNames": ["/traces"],
            "location": "/traces",
            "icon": <AudioLinesIcon className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Services",
            "location": "/service-catalog",
            "pageNames": ["/service-catalog", "/service"],
            "icon": <GlobeIcon className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Service Map",
            "location": "/service-map",
            pageNames: ["/service-map"],
            icon: <FaMapMarked className={"w-4 h-4"}/>
        },
        // {
        //     "tooltip": "Infrastructure",
        //     "location": "/infrastructure",
        //     pageNames: ["/infrastructure"],
        //     icon: <Boxes className={"w-4 h-4"}/>
        // },
        {
            "tooltip": "Metric Explorer",
            "location": "/metric-explorer",
            pageNames: ["/metric-explorer"],
            icon: <Telescope className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Billing",
            "location": "/billing",
            pageNames: ["/billing"],
            icon: <DollarSignIcon className={"w-4 h-4"}/>
        },
        {
            "tooltip": "Settings",
            "location": "/settings",
            pageNames: ["/settings"],
            icon: <IoMdSettings className={"w-4 h-4"}/>
        }
    ]

    return (
        <div className={"w-12 border-r bg-backgroundmedium"}>
            <CornerSquare/>
            <div className={"pt-2 w-12"}>
                {icons.map((icon, index) => (
                    <div
                        onClick={() => navigate(icon.location)}
                        className={"h-10 w-12 justify-center items-center flex"}>
                        <Tooltip delayDuration={10}>
                            <TooltipTrigger>
                                <IconWithBorder highlighted={icon.pageNames.includes(location.pathname)} key={index}
                                                icon={icon.icon}/>
                            </TooltipTrigger>
                            <TooltipContent side={"right"} className={"bg-backgroundlight border text-textlight rounded"}>
                                {icon.tooltip}
                            </TooltipContent>
                        </Tooltip>
                    </div>
                ))}
                {/*Treat logout as a separete case as we just logout, so no navigation.*/}
                <div className={"h-10 w-12 justify-center items-center flex"} onClick={() => {
                    jwtContext.logout()
                    window.location.href = "/login"
                }}>
                    <Tooltip delayDuration={10}>
                        <TooltipTrigger>
                            <IconWithBorder icon={<LogOutIcon className={"w-4 h-4"}
                            />}/>
                        </TooltipTrigger>
                        <TooltipContent side={"right"} className={"bg-backgroundlight border text-textlight rounded"}>
                            Logout 👋
                        </TooltipContent>
                    </Tooltip>
                </div>
            </div>
        </div>
    )

}

interface TopBarProps {
    children?: React.ReactNode;
    title: string;
    disableTimeRange?: boolean;
    disableClusterSelector?: boolean;
}


function TopBar(props: TopBarProps) {
    const [trialEnd, setTrialEnd] = useState<number>();
    const [cookies, setCookie] = useCookies(['trialEnd']);


    useEffect(() => {
        const fetchData = async () => {
            if (!cookies.trialEnd) {
                // If cookie does not exist, fetch data from API
                const response = await getCurrentProductFromApi();

                // Set cookie for 10 minutes (600 seconds)
                setCookie('trialEnd', response.data.currentProductTrialEnd, { path: '/', maxAge: 600 });
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const getTrialEndFromCookie = () => {
            if (cookies.trialEnd) {
                // If cookie exists, set state
                setTrialEnd(cookies.trialEnd);
            }
        };

        getTrialEndFromCookie();

    }, [cookies]);

    function getDaysRemaining(trialEnd: number) {
        const today = new Date();
        const diff = new Date(trialEnd).getTime() - today.getTime();
        return Math.ceil(diff / (1000 * 60 * 60 * 24));
    }

    return (
        <div className={"h-12 w-full border-b bg-backgroundmedium flex justify-between px-4 items-center"}>
            <div className={"text-textlight text-2xl font-semibold leading-loose truncate"}>
                <div className={"flex gap-4 items-center"}>
                    <div>{props.title}</div>
                    {trialEnd !== undefined && trialEnd !== 0 && <div className={"text-sm bg-purple-400/10 border border-purple-400 px-2 py-1"}>{getDaysRemaining(trialEnd  * 1000)} days remaining in free trial</div>}
                </div>
            </div>
            <div className={"flex gap-x-4"}>
                {!props.disableClusterSelector && <ClusterSelector/>}
                {!props.disableTimeRange && <DateTimePickerRange/>}
            </div>
        </div>
    )
}

interface ContentProps {
    children?: React.ReactNode;
}

function Content(props: ContentProps) {
    return (
        <div className={"flex min-h-0 min-w-0 grow"}>
            {props.children}
        </div>
    )
}

interface MainContentProps {
    title: string;
    children?: React.ReactNode;
    disableTimeRange?: boolean;
    disableClusterSelector?: boolean;
}

function MainContent(props: MainContentProps) {
    return (
        <div className={"min-w-0 min-h-0 flex flex-col grow shrink"}>
            <TopBar disableTimeRange={props.disableTimeRange}
                    title={props.title}
                    disableClusterSelector={props.disableClusterSelector}
            />
            <Content>
                {props.children}
            </Content>
        </div>
    )
}

interface BaseViewProps {
    disableTimeRange?: boolean;
    className?: string;
    title: string;
    children?: React.ReactNode;
    disableClusterSelector?: boolean;
}

function BaseView(props: BaseViewProps) {
    return (
        <div className={cn("w-screen h-screen flex", props.className)}>
            <TooltipProvider>
                <LeftBar/>
                <ExpandedChartDialog/>
                <MainContent disableTimeRange={props.disableTimeRange} title={props.title} disableClusterSelector={props.disableClusterSelector}>
                    {props.children}
                </MainContent>
            </TooltipProvider>
        </div>
    );
}

export {
    BaseViewTest,
    BaseView
}