import {BaseView} from "./BaseView";
import React, {useEffect, useState} from "react";
import {SearchBar} from "./ServiceCatalogue";
import axios from "../utility/customAxios";
import {Drawer} from "vaul";
import {cn} from "../components/ui/lib/utils";
import {ChevronDown, InfoIcon, SignpostIcon} from "lucide-react";
import {Input} from "../components/ui/input";
import {
    DropdownMenu,
    DropdownMenuCheckboxItem,
    DropdownMenuContent,
    DropdownMenuTrigger
} from "../components/ui/dropdown-menu";
import {Textarea} from "../components/ui/textarea";
import {Tooltip, TooltipContent, TooltipTrigger} from "../components/ui/tooltip";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";


function MetricExplorer() {
    const [metricText, setMetricText] = React.useState<string>("");
    const [metricMatch, metricMatchSet] = React.useState<string[]>([]);
    const [openMetric, setOpenMetric] = React.useState(false);


    useEffect(() => {
        async function fetchFuzzyMetricNames() {
            const d = axios.post("/api/v1/fuzzyMetricsNames", {
                "metricFuzzyMatch": metricText,
            });
            let awaited = (await d).data;
            metricMatchSet(awaited.metrics);
        }

        fetchFuzzyMetricNames();

    }, [metricText]);


    return <div className={"flex flex-col w-full"}>
        <SearchBar hideNamespaceSelector={true} filter={metricText} setFilter={setMetricText} setAlerting={() => {
        }} setUnhealthy={() => {
        }} searchPlaceholder={"Search metric..."}/>
        <div className={"border bg-backgroundmedium mt-2 flex-col grow shrink text-textmedium"}>
            <MetricList metrics={metricMatch} setOpenMetric={setOpenMetric}/>
        </div>
    </div>
}

function MetricList(props: { metrics: string[], setOpenMetric: (open: boolean) => void }) {
    return <div className="min-w-0 bg-backgroundmedium border-b rounded min-h-0 w-full flex flex-col grow shrink">
        <div className={"flex justify-end border-b"}>
            <div
                className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start gap-4 flex grow shrink">
                <div className={`grow shrink h-full font-semibold leading-8 text-textmedium`}>Metric</div>
                {/*<div className={`grow shrink font-normal leading-8 text-textmedium truncate`}>Custom Name</div>*/}
                {/*<div className={`w-[160px] font-normal leading-8 text-textmedium`}>Type</div>*/}
                {/*<div className={`w-[160px] font-normal leading-8 text-textmedium`}>Unit</div>*/}
            </div>
        </div>
        {props.metrics.map((metric, index) => {
            return <Drawer.Root direction="right">
                <Drawer.Trigger onBlur={(event) => {
                    event.preventDefault()
                }}>
                    <div
                        key={index}
                        onClick={() => {
                            props.setOpenMetric(true);
                        }}
                        className={"flex pl-4 text-start items-center text-textmedium h-[36px] hover:bg-backgroundlight hover:cursor-pointer border-b"}>{metric}</div>
                </Drawer.Trigger>
                <Drawer.Portal>
                    <Drawer.Content
                        className="select-text flex border-l flex-col h-full w-1/2 mt-24 fixed bottom-0 right-0 bg-background">
                        <MetricDetails metric={metric}/>
                    </Drawer.Content>
                </Drawer.Portal>
            </Drawer.Root>
        })}
    </div>
}

enum MetricType {
    Gauge = "gauge",
    Counter = "counter",
    Histogram = "histogram",
    Unknown = "unknown"
}

interface MetricDetails {
    name: string,
    customName: string,
    type: MetricType,
    unit: string,
    description: string,
    tags: Tag[]
}

interface Tag {
    tagKey: string,
    count: number
    tagValues: string[]
}

function MetricDetails(props: { metric: string }) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [isEditing, setEditing] = useState<boolean>(false);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const [attributes, setAttributes] = React.useState<Map<string, string[]>>(new Map());

    const [metricDetails, setMetricDetails] = useState<MetricDetails>({
        name: props.metric,
        customName: "",
        type: MetricType.Unknown,
        unit: "",
        description: "",
        tags: []
    } as MetricDetails);

    useEffect(() => {
        async function fetchMetricDetails() {
            try {
                const d = axios.get("/api/v1/metric/metadata?name=" + props.metric);
                let awaited = (await d).data;
                setMetricDetails(awaited.metric);
            } catch (e) {
                console.debug(e)
            }
        }

        fetchMetricDetails();
    }, []);

    useEffect(() => {
        async function fetchMetricAttributes() {
            if (metricDetails.name && metricDetails.name !== "") {
                const d = axios.post("/api/v1/metricAttributes", {
                    "metricName": metricDetails.name,
                    "startTime": Math.floor((timeRange.getStartEnd())[0].getTime() / 1000),
                    "endTime": Math.floor((timeRange.getStartEnd())[1].getTime() / 1000)
                });
                let awaited = (await d).data;
                let newAttributes = new Map<string, string[]>();
                for (const attribute in awaited.attributes) {
                    newAttributes.set(attribute, awaited.attributes[attribute]);
                }

                setAttributes(newAttributes);
            }
        }

        fetchMetricAttributes();


    }, [metricDetails]);

    function getStyle() {
        return "border border-border bg-backgroundlight hover:cursor-pointer hover:border-primary rounded px-2 hover:text-textlight"
    }


    return <div data-vaul-no-drag className={"flex justify-between text-textmedium h-full"}>
        <div className={"flex flex-col w-full m-4 gap-y-4 "}>
            <div className={"flex gap-2"}>
                <SignpostIcon className={"text-primary"}/>
                <div className="font-semibold text-textmedium">{metricDetails.name}</div>
            </div>

            <div className={"flex border bg-backgroundmedium text-textmedium w-full py-2 px-2 justify-between"}>
                <div className={"flex flex-col gap-2 w-full ml-2 mr-4"}>
                    <div className={"flex"}>
                        <div className={"flex w-[280px] font-semibold items-center"}> Custom Name:</div>
                        <Input
                            value={metricDetails.customName}
                            onChange={(e) => {
                                setMetricDetails((prev) => {
                                    return {...prev, customName: e.target.value}
                                })
                            }}
                            disabled={!isEditing}
                            className={getStyle() + " h-[48px]"}
                            placeholder={metricDetails.customName == "" ? "Custom name" : metricDetails.customName}/>

                    </div>
                    <div className={"flex"}>
                        <div className={"flex w-[280px] items-center justify-start font-semibold"}> Type:</div>
                        <DropdownMenu open={isEditing && dropdownOpen}>
                            <DropdownMenuTrigger asChild>
                                <div className={cn("flex w-full items-center justify-start", getStyle())}
                                     onClick={() => setDropdownOpen(true)}
                                     onBlur={() => setDropdownOpen(false)}>
                                    <Input
                                        className={"border-0"}
                                        value={metricDetails.type}
                                        disabled={!isEditing}/>
                                    {isEditing && <ChevronDown className={"text-textmedium"}/>}
                                </div>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className={"bg-backgroundmedium"} onInteractOutside={
                                () => setDropdownOpen(false)
                            }>
                                {Object.values(MetricType).map((type) => {
                                    return <DropdownMenuCheckboxItem
                                        className={"text-textmedium hover:bg-backgroundlight"}
                                        checked={type === metricDetails.type}
                                        onCheckedChange={() => {
                                            setMetricDetails((prev) => {
                                                return {...prev, type: type as MetricType}
                                            })
                                            setDropdownOpen(false)
                                        }}
                                    >{type}</DropdownMenuCheckboxItem>
                                })}
                            </DropdownMenuContent>
                        </DropdownMenu>

                    </div>
                    <div className={"flex"}>
                        <div className={"w-[280px] font-semibold flex items-center"}> Unit:</div>
                        <Input
                            value={metricDetails.unit}
                            onChange={(e) => {
                                setMetricDetails((prev) => {
                                    return {...prev, unit: e.target.value}
                                })
                            }}
                            disabled={!isEditing}
                            className={getStyle()}
                            placeholder={metricDetails.unit == "" ? "Unit" : metricDetails.unit}/>

                    </div>
                    <div className={"flex"}>
                        <div className={"w-[280px] font-semibold flex items-center"}> Description:</div>
                        <Textarea
                            value={metricDetails.description}
                            onChange={(e) => {
                                setMetricDetails((prev) => {
                                    return {...prev, description: e.target.value}
                                })
                            }}
                            disabled={!isEditing}
                            className={getStyle()}
                            placeholder={metricDetails.description}/>

                    </div>


                </div>
                <div className={"flex justify-end items-start"}>
                    {!isEditing && <div
                        className={"border border-primary bg-primarytransparent hover:cursor-pointer rounded px-2 py-1 hover:border-primaryhover hover:text-textlight"}
                        onClick={() => {
                            setEditing(true);
                        }}>Edit
                    </div>}
                    {isEditing && <div
                        className={"border border-primary bg-primarytransparent hover:cursor-pointer rounded px-2 py-1 hover:border-primaryhover hover:text-textlight"}
                        onClick={() => {
                            setEditing(false);
                            // make the backend request for saving the metric here.
                            axios.post("/api/v1/metric/update", {
                                "metricName": metricDetails.name,
                                "customName": metricDetails.customName,
                                "type": metricDetails.type,
                                "unit": metricDetails.unit,
                                "description": metricDetails.description
                            }).then(
                                (response) => {
                                    console.log(response);
                                }
                            ).catch((error) => {
                                console.log(error);
                            });
                        }}>Save
                    </div>}
                </div>

            </div>
            <div className={"border bg-backgroundmedium flex-col grow shrink text-textmedium w-full overflow-y-auto"}>
                <div className={"flex justify-end border-b"}>
                    <div
                        className={"w-full flex-none h-[48px] ml-4 rounded-tl rounded-tr flex grow shrink items-center justify-start"}>
                        <div className={`w-[280px] leading-8 text-textmedium truncate font-semibold`}>Tag Key</div>
                        <div className={"flex justify-center items-center gap-1"}>
                            <div className={`leading-8 text-textmedium font-semibold`}>Tag Values</div>
                            <Tooltip delayDuration={20}>
                                <TooltipTrigger>
                                    <InfoIcon
                                        className={"text-textdark hover:cursor-pointer hover:text-primary w-3 h-3"}/>
                                </TooltipTrigger>
                                <TooltipContent side={"top"} className={"bg-backgroundmedium border rounded"}>Shows
                                    maximum
                                    of 10 tag values.</TooltipContent>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className={"flex flex-col gap-4 mx-4 my-4 justify-center"}>
                    {attributes.size > 0 && Array.from(attributes.keys()).map((tagKey, index) => {
                        return <div className={"flex justify-start items-start"}>
                            <Tooltip delayDuration={10}>
                                <TooltipTrigger>
                                    <div
                                        className={"font-semibold w-[280px] text-start flex-none truncate"}>{tagKey}</div>
                                </TooltipTrigger>
                                <TooltipContent side={"left"}
                                                className={"bg-backgroundmedium border rounded"}>{tagKey}</TooltipContent>
                            </Tooltip>
                            {/*<div className={"font-semibold w-[180px] flex-none truncate"}>{tagKey}</div>*/}
                            <div className={"flex flex-wrap gap-2 items-start justify-start"}>
                                {attributes.get(tagKey)?.slice(0, 10).map((tagValue, index) => {
                                    return <div
                                        className={"bg-backgroundlight px-1 py-1 rounded border flex-wrap"}>{tagValue}
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </div>


            </div>

        </div>
    </div>

}

const MetricExplorerView = () => {
    return (
        <BaseView title={"Metric Explorer"} disableClusterSelector={true}>
            <div className={"flex grow shrink p-4 overflow-y-auto justify-center w-full min-w-0 min-h-0"}>
                <MetricExplorer/>
            </div>
        </BaseView>
    )
}

export default MetricExplorerView;