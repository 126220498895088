import {MetricChartWidget, Widget} from "../internalwidgets";
import {GridStack} from "gridstack";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import {cn} from "../../ui/lib/utils";
import {removeWidget} from "../utils";
import {MetoroMetricsChart, MetoroMetricsChartProps} from "../../../pages/MetricsTest";
import {useSelector} from "react-redux";
import timerange from "../../../store/reducers/timerange";
import {Dialog, DialogContent} from "../../ui/dialog";
import {MetricSelectorPanel} from "./MetricSelector";
import {Button} from "../../ui/button";
import {MdModeEdit} from "react-icons/md";
import {XIcon} from "lucide-react";
import {useSearchParams} from "react-router-dom";

export const MetricChart = (props: {
    editable: boolean,
    id?: string,
    widget: MetricChartWidget,
    grids: Map<string, GridStack>,
    setGrids: Dispatch<SetStateAction<Map<string, GridStack>>>,
    widgets: Map<string, Widget>,
    setWidgets: Dispatch<SetStateAction<Map<string, Widget>>>,
    updateParentOfMount?: (id: string) => void
    ref?: React.MutableRefObject<any>
}) => {
    const [id] = React.useState<string>(props.id ?? "id-" + uuidv4())
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [searchParams, setSearchParams] = useSearchParams()
    const [environments, setEnvironments] = useState<string[]>([]);
    const [chartProps, setChartProps] = React.useState<MetoroMetricsChartProps>({
        startTime: Math.floor(timeRange.getStartEnd()[0].getTime() / 1000),
        endTime: Math.floor(timeRange.getStartEnd()[1].getTime() / 1000),
        metricName: props.widget.metricName,
        aggregation: props.widget.aggregation,
        type: props.widget.type,
        filters: props.widget.filters,
        excludeFilters: props.widget.excludeFilters,
        splits: props.widget.splits,
        title: props.widget.title,
        metricType: props.widget.metricType,
        functions: props.widget.functions,
    })

    useEffect(() => {
        let environments = searchParams.get("environment");
        if (environments !== null && environments !== "") {
            setEnvironments([environments])
        }
        if (environments === "") {
            setEnvironments([])
        }
    }, [searchParams])

    useEffect(() => {
        setChartProps((prev) => {
            let filtersToUse = prev.filters ?? new Map<string, string[]>();
            if (environments.length > 0 && environments[0] !== "") {
                let envToUse = environments.filter((env) => env !== "")
                filtersToUse.set("environment", envToUse)
            } else {
                filtersToUse.delete("environment")
            }
            return {
                ...prev,
                filters: filtersToUse
            }
        })
    }, [environments]);

    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

    useEffect(() => {
        props.setWidgets((prev) => {
            let newWidgets = new Map<string, Widget>(prev)
            newWidgets.set(id, props.widget)
            return newWidgets
        })

        if (props.updateParentOfMount !== undefined) {
            props.updateParentOfMount(id)
        }
    }, [])

    useEffect(() => {
        let updatedWidget = {
            ...props.widget,
            metricName: chartProps.metricName,
            aggregation: chartProps.aggregation,
            type: chartProps.type,
            filters: chartProps.filters,
            excludeFilters: chartProps.excludeFilters,
            splits: chartProps.splits,
            title: chartProps.title,
            metricType: chartProps.metricType,
            functions: chartProps.functions,
        } as MetricChartWidget
        props.setWidgets((prev) => {
            let newWidgets = new Map<string, Widget>(prev)
            newWidgets.set(id, updatedWidget)
            return newWidgets
        })
    }, [chartProps])

    let leftMargin = "pl-2"
    if (props.widget.position?.x === 0) {
        leftMargin = "pl-4"
    }
    let rightMargin = "pr-2"
    if ( props.widget.position?.x && props.widget.position.w &&
        props.widget.position.x + props.widget.position.w === 12) {
        rightMargin = "pr-4"
    }
    let topMargin = "pt-2"
    if (props.widget.position?.y === 0) {
        topMargin = "pt-4"
    }
    let bottomMargin = "pb-2"



    return (
        <div className={cn(id, "flex flex-col grow")}>
            <div className={cn("flex grow", leftMargin, topMargin, rightMargin, bottomMargin)}>
                <div className={cn("border flex flex-col grow h-full rounded")}>
                    <div className={"flex border-b draggablehandle justify-between px-2 h-8"}>
                        <div
                            className={"text-textmedium flex-col text-center items-center justify-center mt-[4px] truncate"}>{chartProps.title}</div>
                        <div className={"flex gap-2"}>
                            {props.editable &&
                                <Dialog open={isDialogOpen}>
                                    <div
                                        className={"flex flex-col justify-center items-center text-center grow shrink"}>
                                        <Button
                                            className={"h-6 px-2 bg-primarytransparent rounded border-primary border text-textmedium"}
                                            onClick={() => {
                                                setIsDialogOpen(true)
                                            }}>
                                            <div className={"flex gap-2"}>
                                                <div className={"flex flex-col justify-center"}>
                                                    <MdModeEdit className={"text-textmedium"}/>
                                                </div>
                                                <div>Edit</div>
                                            </div>
                                        </Button>
                                    </div>

                                    <DialogContent
                                        onInteractOutside={() => {
                                            setIsDialogOpen(false)
                                        }}
                                        onAbort={() => {
                                            setIsDialogOpen(false)
                                        }} onFocusOutside={
                                        () => {
                                            setIsDialogOpen(false)
                                        }
                                    } className={"border-none bg-none w-[80vw] h-[80vh] m-0 p-0"}>
                                        <MetricSelectorPanel chartProps={
                                            chartProps
                                        } setChartProps={
                                            setChartProps
                                        } setIsOpen={setIsDialogOpen}
                                        />
                                    </DialogContent>
                                </Dialog>
                            }
                            {props.editable &&
                                <div
                                    className={"flex flex-col justify-center items-center text-center grow shrink"}>
                                    <Button
                                        className={"h-6 px-2 bg-primarytransparent border-primary rounder  border text-textmedium"}
                                        onClick={() => {
                                            removeWidget(id, props.grids, props.setGrids, props.widgets, props.setWidgets);
                                        }}>
                                        <div className={"flex"}>
                                            <div className={"flex flex-col justify-center"}>
                                                <XIcon className={"h-4 text-textmedium"}/>
                                            </div>
                                            <div className={"flex flex-col justify-center"}>Delete</div>
                                        </div>
                                    </Button>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={"flex grow"}>
                        <div className={cn("flex grow")}>
                            <MetoroMetricsChart
                                className={"flex grow shrink"}
                                startTime={Math.floor(timeRange.getStartEnd()[0].getTime() / 1000)}
                                endTime={Math.floor(timeRange.getStartEnd()[1].getTime() / 1000)}
                                metricName={chartProps.metricName}
                                aggregation={chartProps.aggregation}
                                type={chartProps.type}
                                filters={chartProps.filters}
                                excludeFilters={chartProps.excludeFilters}
                                splits={chartProps.splits}
                                styling={{borderless: true}}
                                metricType={chartProps.metricType}
                                functions={chartProps.functions}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}