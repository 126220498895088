import React from "react";
import {AiOutlineKubernetes} from "react-icons/ai";
import {K8sPodDetails} from "./model";
import yaml from "js-yaml";
import {V1DaemonSet, V1Deployment, V1Pod, V1ReplicaSet, V1StatefulSet} from "@kubernetes/client-node";
import hdate from "human-date";
import DeploymentYamlDialog from "./DeploymentYamlDialog";
import {Button} from "../ui/button";
import {FrownIcon} from "lucide-react";
import {LoadingSpinner} from "../ui/customSpinner";
import {GetPodsResponse, K8sResourceSummary, K8sSummaryResponseV2} from "./KubernetesView";

function SummaryTable(props: {
    summary?: K8sSummaryResponseV2
    pods?: GetPodsResponse
}) {
    if (props.summary === undefined) {
        return <div className={"bg-backgroundmedium rounded border border-border flex grow"}>
            <div className={"flex grow text-textmedium justify-center"}>
                <div className={"flex text-center items-center flex-col grow justify-center text-lg"}>
                    <LoadingSpinner className={`z-40"}`}/>
                </div>
            </div>
        </div>
    }

    if (props.summary.k8sResourceSummary === undefined || props.summary.k8sResourceSummary.length === 0) {
        return <div className={"bg-backgroundmedium rounded border border-border flex grow"}>
            <div className={"flex grow text-textmedium justify-center"}>
                <div className="flex flex-row justify-center my-8 space-x-2 items-center">
                    <div className={"text-textdark text-center"}>No yaml found</div>
                    <FrownIcon className={"text-textdark"}/>
                </div>
            </div>
        </div>
    }

    let validResourceYaml = props.summary.k8sResourceSummary.filter(sum => {
        return sum.resourceYaml !== undefined && sum.resourceYaml !== ""
    })


    if (validResourceYaml === undefined || validResourceYaml.length === 0) {
        return <div className={"bg-backgroundmedium rounded border border-border flex grow"}>
            <div className={"flex grow text-textmedium justify-center"}>
                <div className="flex flex-row justify-center my-8 space-x-2 items-center">
                    <div className={"text-textdark text-center"}>No yaml found</div>
                    <FrownIcon className={"text-textdark"}/>
                </div>
            </div>
        </div>
    }

    // The kind should be the same for both. If not, we will just use the first one
    let kind = "";
    if (props.summary !== undefined && props.summary.k8sResourceSummary.length > 0) {
        kind = props.summary.k8sResourceSummary[0].kind;
    }

    const isAggregate = validResourceYaml.length > 1

    let creationTimestamp: Date | undefined
    let expectedReplicas: number = 0
    let runningReplicas: number = 0
    // Cannot be a Pod as we are not showing Pod details in this component
    if (isAggregate) {
        validResourceYaml.forEach((sum) => {
            let parsedYaml = yaml.load(sum.resourceYaml)
            let k8sObj;
            if (kind === "Deployment" || kind === "StatefulSet") {
                k8sObj = parsedYaml as V1Deployment;
                creationTimestamp = k8sObj?.metadata?.creationTimestamp
                expectedReplicas = expectedReplicas + (k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0)
                runningReplicas = runningReplicas + (k8sObj?.status?.availableReplicas ? k8sObj?.status?.availableReplicas : 0)
            } else if (kind === "StatefulSet") {
                k8sObj = parsedYaml as V1StatefulSet;
                creationTimestamp = k8sObj?.metadata?.creationTimestamp
                expectedReplicas = expectedReplicas + (k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0)
                runningReplicas = runningReplicas + (k8sObj?.status?.readyReplicas ? k8sObj?.status?.readyReplicas : 0)
            } else if (kind === "ReplicaSet") {
                k8sObj = parsedYaml as V1ReplicaSet;
                creationTimestamp = k8sObj?.metadata?.creationTimestamp
                expectedReplicas = expectedReplicas + (k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0)
                runningReplicas = runningReplicas + (k8sObj?.status?.readyReplicas ? k8sObj?.status?.readyReplicas : 0)
            } else if (kind === "DaemonSet") {
                k8sObj = parsedYaml as V1DaemonSet;
                creationTimestamp = k8sObj?.metadata?.creationTimestamp
                expectedReplicas = expectedReplicas + (k8sObj?.status?.desiredNumberScheduled ? k8sObj?.status?.desiredNumberScheduled : 0)
                runningReplicas = runningReplicas + (k8sObj?.status?.currentNumberScheduled ? k8sObj?.status?.currentNumberScheduled : 0)
            }
        })
    } else {
        let parsedYaml = yaml.load(validResourceYaml[0].resourceYaml)
        let k8sObj;
        if (kind === "Deployment" || kind === "StatefulSet") {
            k8sObj = parsedYaml as V1Deployment;
            creationTimestamp = k8sObj?.metadata?.creationTimestamp
            expectedReplicas = k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0
            runningReplicas = k8sObj?.status?.availableReplicas ? k8sObj?.status?.availableReplicas : 0
        } else if (kind === "StatefulSet") {
            k8sObj = parsedYaml as V1StatefulSet;
            creationTimestamp = k8sObj?.metadata?.creationTimestamp
            expectedReplicas = k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0
            runningReplicas = k8sObj?.status?.readyReplicas ? k8sObj?.status?.readyReplicas : 0
        } else if (kind === "ReplicaSet") {
            k8sObj = parsedYaml as V1ReplicaSet;
            creationTimestamp = k8sObj?.metadata?.creationTimestamp
            expectedReplicas = k8sObj?.spec?.replicas ? k8sObj?.spec?.replicas : 0
            runningReplicas = k8sObj?.status?.readyReplicas ? k8sObj?.status?.readyReplicas : 0
        } else if (kind === "DaemonSet") {
            k8sObj = parsedYaml as V1DaemonSet;
            creationTimestamp = k8sObj?.metadata?.creationTimestamp
            expectedReplicas = k8sObj?.status?.desiredNumberScheduled ? k8sObj?.status?.desiredNumberScheduled : 0
            runningReplicas = k8sObj?.status?.currentNumberScheduled ? k8sObj?.status?.currentNumberScheduled : 0
        }
    }

    function getRunningPodsString(runningPods: number | undefined, totalPods: number | undefined) {
        let runningString = ""
        if (runningPods !== undefined) {
            runningString = `${runningPods}`
        }
        if (totalPods !== undefined) {
            runningString += `/${totalPods}`
        }
        if (runningString !== "") {
            runningString += " running"
        } else {
            return ""
        }
        return runningString
    }

    let runningString = getRunningPodsString(runningReplicas, expectedReplicas);

    let restarts = 0
    let restartSet = false;
    // TODO: To replace!!!
    // if (props.podDetails && props.podDetails.length > 0) {
    //     props.podDetails.forEach((pod) => {
    //         let parsedYaml = yaml.load(pod.yaml)
    //         let k8sPod = parsedYaml as V1Pod
    //         if (k8sPod.status && k8sPod.status.containerStatuses) {
    //             k8sPod.status.containerStatuses.forEach((containerStatus) => {
    //                 if (!restartSet) {
    //                     restartSet = true
    //                 }
    //                 restarts += containerStatus.restartCount
    //             })
    //         }
    //     })
    // }

    return (
        <div className={"bg-backgroundmedium rounded border border-border grow shrink"}>
            <div className={"flex grow flex-col gap-8 m-4"}>
                <div className={"flex justify-between"}>
                    <div className={"flex flex-row justify-between grow"}>
                        <div className={"text-lg font-semibold text-textmedium"}>
                            Summary
                        </div>
                        <DeploymentYamlDialog
                            kind={kind}
                            yamls={validResourceYaml.reduce((acc: Map<string, string>, resource: K8sResourceSummary) => {
                                acc.set(resource.environment, resource.resourceYaml);
                                return acc;
                            }, new Map<string, string>())}
                            dialogTrigger={
                                <Button asChild
                                        className={"text-textmedium rounded bg-primarytransparent border-primary border hover:bg-primary hover:cursor-pointer"}>
                                    <div className="flex items-center space-x-2">
                                        <div className="text-textmedium hover:text-textlight">View Yaml</div>
                                    </div>
                                </Button>
                            }
                        />
                    </div>
                </div>
                <div className={"grid grid-rows-2 lg:grid-rows-1 grid-cols-1 lg:grid-cols-2 gap-y-4"}>
                    <div className={"flex flex-col gap-4"}>
                        <div className={"flex gap-16"}>
                            <div className={"text-textmedium font-bold w-[100px]"}>Pods</div>
                            <div className={"text-textmedium"}>{runningString}</div>
                        </div>
                        {restartSet && <div className={"flex gap-16"}>
                            <div className={"text-textmedium font-bold w-[100px]"}>Restarts</div>
                            <div className={"text-textmedium"}>{restarts}</div>
                        </div>
                        }
                    </div>
                    <div className={"flex flex-col gap-4"}>
                        <div className={"flex gap-16"}>
                            <div className={"text-textmedium font-bold w-[100px]"}>Type</div>
                            <DeploymentYamlDialog
                                kind={kind}
                                yamls={validResourceYaml.reduce((acc: Map<string, string>, resource: K8sResourceSummary) => {
                                    acc.set(resource.environment, resource.resourceYaml);
                                    return acc;
                                }, new Map<string, string>())}
                                dialogTrigger={<div className={"text-textmedium"}>{
                                    <div
                                        className="inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 border-transparent text-primary-foreground shadow hover:bg-primary/80 rounded bg-blue-500 gap-2 text-white hover:cursor-pointer">
                                        <AiOutlineKubernetes className={"text-white h-4 w-4"}/>{kind}
                                    </div>
                                }</div>}
                            />
                        </div>
                        {!isAggregate && <div className={"flex gap-16"}>
                            <div className={"text-textmedium font-bold w-[100px] truncate"}>Created at</div>
                            <div
                                className={"text-textmedium"}>{hdate.prettyPrint(creationTimestamp, {showTime: true})}</div>
                        </div>}
                        {isAggregate && <div className={"flex gap-16"}>
                            <div className={"text-textmedium font-bold w-[100px] truncate"}>Deployed in</div>
                            <div
                                className={"text-textmedium"}>{validResourceYaml.length} environments
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default SummaryTable
