import React, {ReactNode, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {reviver} from "./Dashboarding/Dashboard";
import {
    createMetricChartPropsFromMetricChartWidget,
    MetoroMetricsChart,
    MetoroMetricsChartProps
} from "../pages/MetricsTest";
import {MetricChartWidget} from "./Dashboarding/internalwidgets";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import {Dialog, DialogContent} from "./ui/dialog";


export function ExpandedChartDialog() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [chartProps, setChartProps] = React.useState<MetoroMetricsChartProps | undefined>(undefined)
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);

    useEffect(() => {
        let fullScreenChart = searchParams.get("fullscreenChart")
        let injectedChart: MetricChartWidget | undefined = undefined

        if (fullScreenChart !== null) {
            injectedChart = JSON.parse(decodeURIComponent(fullScreenChart), reviver) as MetricChartWidget
        }
        if (injectedChart === undefined) {
            return
        }

        let metoroChartProps = createMetricChartPropsFromMetricChartWidget(injectedChart, Math.floor(timeRange.getStartEnd()[0].getTime() / 1000), Math.floor(timeRange.getStartEnd()[1].getTime() / 1000))
        setChartProps(metoroChartProps)

        setIsDialogOpen(true)
    }, [searchParams]);


    return <Dialog open={isDialogOpen}>
        <DialogContent className={"w-[75vw] h-[75vh] p-0"} onInteractOutside={
            () => {
                setSearchParams(prev => {
                    let existing = new URLSearchParams(window.location.search)
                    existing.delete("fullscreenChart")
                    return existing
                })
                setIsDialogOpen(false)
            }}>
            {chartProps && <MetoroMetricsChart
                {...chartProps}
            />}
        </DialogContent>
    </Dialog>
}

