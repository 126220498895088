import {BaseView} from "./BaseView";
import {MetricQuerySelector, RateFunctionType} from "../components/Dashboarding/widgets/MetricSelector";
import React, {useEffect, useState} from "react";
import {
    ChartType,
    GetAggregateMetricEvaluation,
    MetoroMetricsChart,
    MetoroMetricsChartProps,
    Metric,
    MetricType, TimeSeries
} from "./MetricsTest";
import {useSelector} from "react-redux";
import timerange from "../store/reducers/timerange";
import axios from "../utility/customAxios";
import {Drawer} from "vaul";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "../components/ui/tabs";
import CopyToClipboardButton from "../components/CopyToClipboardButton";
import PrettyYamlViewer from "../components/K8sInfo/YamlViewer";
import yaml from "js-yaml";
import {V1Node} from "@kubernetes/client-node";
import {Collapsible, CollapsibleContent} from "components/ui/collapsible";
import {CollapsibleTrigger} from "../components/ui/collapsible";
import {ChevronDown, ChevronRight, InfoIcon} from "lucide-react";
import {Tooltip, TooltipContent, TooltipTrigger} from "../components/ui/tooltip";
import {AggregationFunction, WindowUnit} from "./alerts/MetricAlert";
import {useDebouncedCallback} from "use-debounce";

interface Node {
    name: string
    environment: string
    status: string
    yaml: string
    labels: Map<string, string>
}

function Infrastructure() {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const startEnd = timeRange.getStartEnd();
    const debouncedUpdateMetricAggregateEvaluation = useDebouncedCallback(updateMetricAggregateEvaluation, 10);
    const [metricAggregateEvaluation, setMetricAggregateEvaluation] = React.useState<Metric>();

    const [chartProps, setChartProps] = useState<MetoroMetricsChartProps>({
        metricType: MetricType.Metric,
        startTime: Math.floor(startEnd[0].getTime() / 1000),
        endTime: Math.floor(startEnd[1].getTime() / 1000),
        aggregation: "max",
        title: "",
        metricName: "node_resources_cpu_usage_seconds_total",
        functions: [{id: "1", functionType: RateFunctionType.MonotonicDifference}],
        filters: new Map<string, string[]>(),
        splits: [],
        type: ChartType.Line,
        apperance: {
            hideMetricSelector: true,
            hideAggregationSelector: true,
        },
    });

    async function updateMetricAggregateEvaluation(chartProps: MetoroMetricsChartProps,
                                                   startTime: number,
                                                   endTime: number,
                                                   setMetric: (value: (((prevState: (Metric | undefined)) => (Metric | undefined)) | Metric | undefined)) => void) {

        let filters = chartProps.filters;
        if (filters === undefined) {
            filters = new Map<string, string[]>();
        }
        let excludeFilters = chartProps.excludeFilters;
        if (excludeFilters === undefined) {
            excludeFilters = new Map<string, string[]>();
        }

        let splits = chartProps.splits ? chartProps.splits.slice() : [];
        splits.push("kubernetes.io/hostname");
        splits.push("environment");

        try {
            const request = {
                metricName: chartProps.metricName,
                startTime: startTime,
                endTime: endTime,
                filters: filters,
                excludeFilters: excludeFilters,
                splits: splits,
                aggregation: chartProps.aggregation,
                isRate: false,
                functions: chartProps.functions,
                aggregateParams: {
                    evaluationFunction: AggregationFunction.Average,
                    // Window size in seconds. This is the time window over which the aggregation function is applied.
                    window: 15,
                    windowUnit: WindowUnit.Minutes,
                    evaluationSplits: splits
                },
                limitResults: false // we should never limit results for aggregation. This field is ignored by get aggregation metric endpoint in the backend anyway.
            };
            const awaitedMetrics = await GetAggregateMetricEvaluation(request);
            setMetric(awaitedMetrics.metric);
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        if (chartProps.startTime === 0 || chartProps.endTime === 0) {
            return
        }
        debouncedUpdateMetricAggregateEvaluation(chartProps, chartProps.startTime, chartProps.endTime, setMetricAggregateEvaluation)
    }, [chartProps.startTime, chartProps.endTime, chartProps.filters, chartProps.splits]);

    return <BaseView title={"Infrastructure"} disableClusterSelector={true}>
        <div className={"flex flex-col w-full m-4 gap-4 overflow-y-scroll"}>
            <div className={"flex items-start"}>
                <MetricQuerySelector setChartProps={setChartProps} chartProps={chartProps}/>
            </div>
            <NodeTable cpuMetric={metricAggregateEvaluation}/>
            <HostMetrics filters={chartProps.filters} splits={chartProps.splits}/>
        </div>
    </BaseView>
}


function NodeTable(props: { cpuMetric?: Metric }) {
    if (props.cpuMetric === undefined) {
        return <div
            className="min-w-0 min-h-[200px] bg-backgroundmedium border rounded flex flex-col overflow-y-scroll"></div>
    }
    if (props.cpuMetric.timeSeries === undefined || props.cpuMetric.timeSeries.length === 0) {
        return <div
            className="min-w-0 min-h-[200px] bg-backgroundmedium border rounded flex flex-col overflow-y-scroll"></div>
    }
    const otherAttributesMap = new Map<string, string>();
    Object.keys(props.cpuMetric.timeSeries[0].attributes).forEach((key) => {
        if (key !== "kubernetes.io/hostname" && key !== "environment") {
            otherAttributesMap.set(key, props.cpuMetric!.timeSeries[0].attributes[key])
        }
    })
    return <div className="min-w-0 min-h-[200px] bg-backgroundmedium border rounded flex flex-col overflow-y-scroll">
        <div className={"flex justify-start"}>
            <div
                className="w-full flex-none h-[48px] px-4 py-2 rounded-tl rounded-tr justify-start items-start flex grow shrink">
                <div
                    className={`w-[300px] h-full leading-8 text-textmedium font-semibold`}>
                    <div className={"flex gap-4 items-center"}>
                        <div>Hostname</div>
                        <div
                            className={"border rounded border-primary bg-backgrounddark px-2 py-1 text-textmedium text-sm"}> {props.cpuMetric.timeSeries.length} {props.cpuMetric.timeSeries.length == 1 ? "no results" : (props.cpuMetric.timeSeries.length > 1 ? "results" : "result")}
                        </div>
                    </div>
                </div>
                <div
                    className={`w-[160px] leading-8 text-textmedium truncate font-semibold`}>Status
                </div>
                <div
                    className={`w-[200px] leading-8 text-textmedium truncate font-semibold`}>Environment
                </div>
                {
                    otherAttributesMap.size !== 0 && Array.from(otherAttributesMap).map(([key, value]) => {
                        return <div
                            className={`max-w-[200px] leading-8 text-textmedium text-center text-nowrap font-semibold overflow-x-scroll no-scrollbar bg-backgrounddark px-2 border mr-4`}>{key}</div>
                    })
                }
                <div
                    className={`w-[200px] leading-8 text-textmedium truncate font-semibold`}>CPU Seconds (AVG)
                </div>
            </div>
        </div>
        <div
            className="h-full max-w-full min-w-0 min-h-0 overflow-y-auto scrollMedium border-t rounded-b flex flex-col grow shrink">
            {
                props.cpuMetric && props.cpuMetric.timeSeries.length === 0 &&
                <div className="h-full w-full flex justify-center items-center">
                    <div className="text-textmedium text-lg">No hosts found</div>
                </div>
            }
            {
                props.cpuMetric && props.cpuMetric.timeSeries.map((timeseriesData, index) => {
                    return <ExpandableEntry cpuTimeseriesData={timeseriesData}/>
                })
            }
        </div>
    </div>
}

function ExpandableEntry(props: { cpuTimeseriesData: TimeSeries }) {
    const nodeName = props.cpuTimeseriesData.attributes["kubernetes.io/hostname"];
    const environment = props.cpuTimeseriesData.attributes["environment"];
    const otherAttributesMap = new Map<string, string>();
    Object.keys(props.cpuTimeseriesData.attributes).forEach((key) => {
        if (key !== "kubernetes.io/hostname" && key !== "environment") {
            otherAttributesMap.set(key, props.cpuTimeseriesData.attributes[key])
        }
    });

    const [node, setNode] = useState<Node>();
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const startEnd = timeRange.getStartEnd();
    const [k8sNode, setK8sNode] = useState<V1Node>();
    const [openDialog, setOpenDialog] = useState<boolean>(false);

    function getNodeDetails(nodeName: string) {
        axios.get(`/api/v1/infrastructure/node?nodeName=${nodeName}&startTime=${Math.floor(startEnd[0].getTime() / 1000)}`).then((response) => {
            setNode(response.data.node)
            const parsedYaml = yaml.load(response.data.node.yaml)
            const parsedK8sNode = parsedYaml as V1Node;
            setK8sNode(parsedK8sNode)
            setOpenDialog(true)
        }).catch((error) => {
            console.error(error)
        })
    }

    return <Drawer.Root direction="right" open={openDialog}>
        <Drawer.Trigger asChild onClick={() => getNodeDetails(nodeName)}>
            {nodeName != "" && environment != "" && <div
                className={"pl-4 flex py-2 items-center justify-start hover:bg-backgroundlight hover:cursor-pointer"}
                onClick={() => getNodeDetails(nodeName)}
            >
                <div className={"text-textmedium w-[300px]"}>{nodeName}</div>
                <div className={"w-[160px]"}>
                    <div
                        className={"w-max border rounded py-1 px-2 bg-secondarytransparenter border-secondary text-textlight text-left"}>Running
                    </div>
                </div>
                <div
                    className={"w-[200px] text-textmedium"}>{environment}</div>
                {otherAttributesMap.size !== 0 &&
                    Array.from(otherAttributesMap).map(([key, value]) => {
                        return <div className={"text-textmedium w-[200px]  mr-4"}>{value}</div>
                    })
                }
                <div className={"text-textmedium w-[300px]"}>{props.cpuTimeseriesData.data[0].value.toFixed(2)}</div>
            </div>}
        </Drawer.Trigger>
        <Drawer.Portal>
            <Drawer.Content
                onInteractOutside={() => setOpenDialog(false)}
                className={`select-text flex flex-col rounded-t-[10px] h-full w-1/2 mt-12 fixed bottom-0 right-0`}>
                <HostDetailsSideView node={node} k8sNode={k8sNode}/>
            </Drawer.Content>
        </Drawer.Portal>
    </Drawer.Root>
}


function HostDetailsSideView(props: { node?: Node, k8sNode?: V1Node }) {
    if (props.node === undefined) {
        return <div></div>
    }
    if (props.k8sNode === undefined) {
        return <div></div>
    }
    const [currentTab, setCurrentTab] = useState<string>("hostinfo");
    return <div data-vaul-no-drag className={"h-full bg-backgrounddark border-l pt-12"}>
        <div className={"h-full flex flex-col mx-8 gap-4"}>
            <HostTitle node={props.node} k8sNode={props.k8sNode}/>
            {/*// @ts-ignore*/}
            <Tabs disabledTabClassName={"hidden"}
                  onValueChange={(val) => setCurrentTab(val)}
                  value={currentTab}
                  defaultValue="metadata"
                  className="w-full flex flex-col grow shrink min-h-0 min-w-0">
                <TabsList className="w-full flex-none mb-4 grid grid-cols-3 grid-rows-1 border">
                    <TabsTrigger value="hostinfo">Host Info</TabsTrigger>
                    <TabsTrigger value="yaml">Yaml</TabsTrigger>
                    <TabsTrigger value="metrics">Metrics</TabsTrigger>
                    {/*<TabsTrigger value="container">Containers</TabsTrigger>*/}
                </TabsList>
                <div className={"flex grow shrink justify-start min-h-0 min-w-0"}>
                    <TabsContent
                        className={"flex flex-col grow shrink min-h-0 min-w-0 overflow-y-auto no-scrollbar"}
                        value="hostinfo">
                        <div>
                            <div className={"mt-4 flex flex-col gap-4"}>
                                <HostInfo node={props.node} parsedNode={props.k8sNode}/>
                            </div>
                        </div>
                    </TabsContent>
                    <TabsContent
                        className={"flex flex-col grow shrink min-h-0 min-w-0 overflow-y-auto no-scrollbar"}
                        value="yaml">
                        <div>
                            <div className={"mt-4 flex flex-col gap-4"}>
                                <PrettyYamlViewer yamlString={props.node !== undefined ? props.node.yaml : ""}/>
                            </div>
                        </div>
                    </TabsContent>
                    <TabsContent className={"flex flex-col grow shrink min-h-0 min-w-0 overflow-y-auto no-scrollbar"}
                                 value="metrics">
                        <div>
                            <HostMetrics node={props.node}/>
                        </div>
                    </TabsContent>
                    {/*<TabsContent className={"flex flex-col grow shrink min-h-0 min-w-0"} value="containers">*/}
                    {/*    <div>*/}
                    {/*        TODO: containers*/}
                    {/*    </div>*/}
                    {/*</TabsContent>*/}
                </div>
            </Tabs>
        </div>
    </div>
}


function HostTitle(props: { node?: Node, k8sNode?: V1Node }) {
    if (props.node === undefined) {
        return
    }
    if (props.k8sNode === undefined) {
        return
    }

    return <div className={"flex flex-col"}>
        <div className={"flex w-full justify-between items-center"}>
            <div className={"flex gap-2 items-center"}>
                <div className={"text-textmedium text-lg font-bold text-center"}>{props.node.name}</div>
                <CopyToClipboardButton text={props.node.name}/>
            </div>
            <div
                className={"text-textlight bg-secondarytransparenter border rounded border-secondarytransparent px-1 py-1"}>Running</div>
        </div>
        <div className={"flex"}></div>
        <div className={"flex items-center justify-start gap-2"}>
            <div className={"text-textdark"}>Cluster:</div>
            <div
                className={"text-textmedium"}>{props.node.environment}</div>
        </div>
    </div>
}

function HostInfo(props: { parsedNode?: V1Node, node?: Node}) {
    if (props.parsedNode === undefined) {
        return <div></div>
    }
    if (props.node === undefined) {
        return <div></div>
    }
    const nodeInfo = props.parsedNode.status?.nodeInfo;
    const [isHostSpecOpen, setIsHostSpecOpen] = useState<boolean>(true);
    const copyableValueStyle = "border rounded bg-backgroundlight px-2 py-1 flex items-center gap-1"
    const entryTitleStyle = "font-semibold text-textdark  w-[200px]"
    const [isHostAliasesOpen, setIsHostAliasesOpen] = useState<boolean>(true);
    const addresses = new Map<string, string>();
    if (props.parsedNode.status !== undefined && props.parsedNode.status.addresses !== undefined) {
        props.parsedNode.status.addresses.forEach((address) => {
            addresses.set(address.type, address.address)
        })
    }
    const capacity: Map<string, string> = new Map(Object.entries(props.parsedNode.status?.capacity ? props.parsedNode.status.capacity : {}))
    const [isCapacitySpecOpen, setIsCapacitySpecOpen] = useState<boolean>(true);

    const allocable: Map<string, string> = new Map(Object.entries(props.parsedNode.status?.allocatable ? props.parsedNode.status.allocatable : {}))
    const [isAllocableSpecOpen, setIsAllocableSpecOpen] = useState<boolean>(true);

    // k8sNode.
    return <div className={"flex flex-col gap-4"}>
        {addresses !== undefined && addresses.size > 0 && <div className={"flex"}>
            <Collapsible open={isHostAliasesOpen}>
                <CollapsibleTrigger className={"font-bold text-textmedium text-lg hover:text-textlight"}
                                    onClick={() => setIsHostAliasesOpen(!isHostAliasesOpen)}>
                    <div className={"flex items-center gap-2"}>
                        {!isHostAliasesOpen && <ChevronRight className={"w-5 h-5"}/>}
                        {isHostAliasesOpen && <ChevronDown className={"w-5 h-5"}/>}
                        <div> Host Aliases</div>
                    </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                    <div className={"flex flex-col text-textmedium gap-2 pl-2"}>
                        {Array.from(addresses).map(([key, value]) => {
                            return <div className={"flex items-center"}>
                                <div className={entryTitleStyle}> {key}:</div>
                                <div
                                    className={copyableValueStyle}> {value} <CopyToClipboardButton
                                    text={value}/></div>
                            </div>
                        })}
                    </div>
                </CollapsibleContent>
            </Collapsible>
        </div>}
        {nodeInfo !== undefined && <div className={"flex"}>
            <Collapsible open={isHostSpecOpen}>
                <CollapsibleTrigger className={"font-bold text-textmedium text-lg hover:text-textlight"}
                                    onClick={() => setIsHostSpecOpen(!isHostSpecOpen)}>
                    <div className={"flex items-center gap-2"}>
                        {!isHostSpecOpen && <ChevronRight className={"w-5 h-5"}/>}
                        {isHostSpecOpen && <ChevronDown className={"w-5 h-5"}/>}
                        <div>Host Specification</div>
                    </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                    <div className={"flex flex-col text-textmedium gap-2 pl-2"}>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Operating System:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.operatingSystem} <CopyToClipboardButton
                                text={nodeInfo.operatingSystem}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> OS Image:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.osImage} <CopyToClipboardButton
                                text={nodeInfo.osImage}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Architecture:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.architecture} <CopyToClipboardButton
                                text={nodeInfo.architecture}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Kernel Version:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.kernelVersion} <CopyToClipboardButton
                                text={nodeInfo.kernelVersion}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Machine ID:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.machineID} <CopyToClipboardButton
                                text={nodeInfo.machineID}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Boot ID:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.bootID} <CopyToClipboardButton
                                text={nodeInfo.bootID}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Container Version:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.containerRuntimeVersion}
                                <CopyToClipboardButton text={nodeInfo.containerRuntimeVersion}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Kubelet Version:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.kubeletVersion} <CopyToClipboardButton
                                text={nodeInfo.kubeletVersion}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> Kube Proxy Version:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.kubeProxyVersion} <CopyToClipboardButton
                                text={nodeInfo.kubeProxyVersion}/></div>
                        </div>
                        <div className={"flex items-center"}>
                            <div className={entryTitleStyle}> System UUID:</div>
                            <div
                                className={copyableValueStyle}> {nodeInfo.systemUUID} <CopyToClipboardButton
                                text={nodeInfo.systemUUID}/></div>

                        </div>
                    </div>
                </CollapsibleContent>
            </Collapsible>
        </div>}
        {capacity !== undefined && capacity.size > 0 && <div className={"flex"}>
            <Collapsible open={isCapacitySpecOpen}>
                <CollapsibleTrigger className={"font-bold text-textmedium text-lg hover:text-textlight"}
                                    onClick={() => setIsCapacitySpecOpen(!isCapacitySpecOpen)}>
                    <div className={"flex items-center gap-2"}>
                        {!isCapacitySpecOpen && <ChevronRight className={"w-5 h-5"}/>}
                        {isCapacitySpecOpen && <ChevronDown className={"w-5 h-5"}/>}
                        <div> Host Capacity</div>
                        <Tooltip delayDuration={20}>
                            <TooltipTrigger>
                                <InfoIcon className={"text-textdark w-4 h-4 hover:text-primary"}/>
                            </TooltipTrigger>
                            <TooltipContent side={"right"}
                                            className={"bg-backgroundmedium border rounded px-2 py-1 font-normal"}>The
                                fields in the capacity block indicate the total amount of resources that a Node
                                has.</TooltipContent>
                        </Tooltip>
                    </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                    <div className={"flex flex-col text-textmedium gap-2 pl-2"}>
                        {Array.from(capacity).map(([key, value]) => {
                            return <div className={"flex items-center"}>
                                <div className={entryTitleStyle}> {key[0].toUpperCase() + key.slice(1)}:</div>
                                <div
                                    className={copyableValueStyle}> {value}</div>
                            </div>
                        })}
                    </div>
                </CollapsibleContent>
            </Collapsible>
        </div>}
        {allocable !== undefined && allocable.size > 0 && <div className={"flex"}>
            <Collapsible open={isAllocableSpecOpen}>
                <CollapsibleTrigger className={"font-bold text-textmedium text-lg hover:text-textlight"}
                                    onClick={() => setIsAllocableSpecOpen(!isAllocableSpecOpen)}>
                    <div className={"flex items-center gap-2"}>
                        {!isAllocableSpecOpen && <ChevronRight className={"w-5 h-5"}/>}
                        {isAllocableSpecOpen && <ChevronDown className={"w-5 h-5"}/>}
                        <div> Allocable Resources</div>
                        <Tooltip delayDuration={20}>
                            <TooltipTrigger>
                                <InfoIcon className={"text-textdark w-4 h-4 hover:text-primary"}/>
                            </TooltipTrigger>
                            <TooltipContent side={"right"}
                                            className={"bg-backgroundmedium border rounded px-2 py-1 font-normal"}>The
                                allocatable block indicates the amount of resources on a Node that is available to be
                                consumed by normal Pods.</TooltipContent>
                        </Tooltip>
                    </div>
                </CollapsibleTrigger>
                <CollapsibleContent>
                    <div className={"flex flex-col text-textmedium gap-2 pl-2"}>
                        {Array.from(allocable).map(([key, value]) => {
                            return <div className={"flex items-center"}>
                                <div className={entryTitleStyle}> {key[0].toUpperCase() + key.slice(1)}:</div>
                                <div
                                    className={copyableValueStyle}> {value}</div>
                            </div>
                        })}
                    </div>
                </CollapsibleContent>
            </Collapsible>
        </div>}
    </div>
}

function HostMetrics(props: { node?: Node, filters?: Map<string, string[]>, splits?: string[] }) {
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    const startEnd = timeRange.getStartEnd();
    let filters = props.filters !== undefined ? props.filters : new Map<string, string[]>();
    let splits: string[] = [];
    if (props.node !== undefined) {
        // If node is provided we are at specific node side view.
        filters.set("kubernetes.io/hostname", [props.node.name]);
    } else {
        // If no node is provided, we are at the aggregated view.
        if (props.splits === undefined || props.splits.length === 0) {
            splits = ["kubernetes.io/hostname"];
        } else {
            splits = props.splits;
        }
    }

    return (
        <div className={"flex flex-col space-y-8 mb-4"}>
            <div className={"grid-cols-2 grid gap-4 rounded text-textlight overflow-y-auto"}>
                <MetoroMetricsChart
                    hideOnNoData={false}
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"node_resources_cpu_usage_seconds_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`CPU Usage - Seconds`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    hideOnNoData={true}
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"node_resources_cpu_limit_cores"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`CPU Limit - Cores`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"node_resources_memory_available_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Memory Available - Bytes`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"node_resources_memory_total_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Memory Total - Bytes`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"node_resources_memory_free_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Memory Free - Bytes`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={true}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"node_resources_memory_limit_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Memory Limit - Bytes`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={true}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"node_resources_disk_read_bytes_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Disk Bytes Read`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={true}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"node_resources_disk_written_bytes_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Disk Bytes Written`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={true}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"node_resources_disk_used_bytes"}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Disk Usage`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"container_net_tcp_active_connections"}
                    aggregation={"sum"}
                    type={ChartType.Line}
                    title={`Open TCP Connections`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                    functions={[]}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"node_net_received_bytes_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Network Bytes Received`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
                <MetoroMetricsChart
                    className={"bg-backgroundmedium border h-[256px] flex grow shrink relative"}
                    hideOnNoData={false}
                    startTime={Math.floor(startEnd[0].getTime() / 1000)}
                    endTime={Math.floor(startEnd[1].getTime() / 1000)}
                    metricName={"node_net_transmitted_bytes_total"}
                    functions={[{id: "1", functionType: RateFunctionType.MonotonicDifference}]}
                    aggregation={"max"}
                    type={ChartType.Line}
                    title={`Network Bytes Transmitted`}
                    splits={splits}
                    filters={filters}
                    styling={{borderless: true}}
                    metricType={MetricType.Metric}
                />
            </div>
        </div>
    )

}


export default Infrastructure;
