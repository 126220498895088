import {useDispatch, useSelector} from "react-redux";
import timerange, {set} from "../../store/reducers/timerange";
import React, {useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from "react";
import {TimeRange} from "../../types/time";
import {HiPause} from "react-icons/hi2";
import {IoPlaySharp} from "react-icons/io5";


import {CalendarIcon, ChevronLeftIcon, ChevronRightIcon, X} from 'lucide-react';
import {
    AriaDatePickerProps,
    AriaTimeFieldProps,
    CalendarProps,
    DateValue,
    TimeValue,
    useButton,
    useCalendar,
    useCalendarCell,
    useCalendarGrid,
    useDateField,
    useDatePicker,
    useDateSegment,
    useLocale,
    useTimeField,
} from 'react-aria';
import {
    CalendarState,
    DateFieldState,
    DatePickerState,
    DatePickerStateOptions,
    TimeFieldStateOptions,
    useCalendarState,
    useDateFieldState,
    useDatePickerState,
    useTimeFieldState,
} from 'react-stately';
import {
    CalendarDate,
    createCalendar,
    fromDate,
    getLocalTimeZone,
    getWeeksInMonth,
    isToday as _isToday,
    parseDateTime,
    toCalendarDateTime,
} from '@internationalized/date';
import {DateSegment as IDateSegment} from '@react-stately/datepicker';
import {Button} from "./button";
import {cn} from "./lib/utils";
import {Popover, PopoverContent, PopoverTrigger} from "./popover";
import {CaretDownIcon} from "@radix-ui/react-icons";

var _ = require('lodash');

function Calendar(props: CalendarProps<DateValue>) {
    const prevButtonRef = React.useRef<HTMLButtonElement | null>(null);
    const nextButtonRef = React.useRef<HTMLButtonElement | null>(null);

    const {locale} = useLocale();
    const state = useCalendarState({
        ...props,
        locale,
        createCalendar,
    });
    const {
        calendarProps,
        prevButtonProps: _prevButtonProps,
        nextButtonProps: _nextButtonProps,
        title,
    } = useCalendar(props, state);
    const {buttonProps: prevButtonProps} = useButton(_prevButtonProps, prevButtonRef);
    const {buttonProps: nextButtonProps} = useButton(_nextButtonProps, nextButtonRef);

    return (
        <div {...calendarProps} className="space-y-4 text-textmedium">
            <div className="relative flex items-center justify-center pt-1">
                <Button
                    {...prevButtonProps}
                    ref={prevButtonRef}
                    variant="outline"
                    className={cn('absolute left-1 h-7 w-7 border rounded bg-transparent p-0 opacity-50 hover:opacity-100')}
                >
                    <ChevronLeftIcon className="h-4 w-4"/>
                </Button>
                <div className="text-sm font-medium">{title}</div>
                <Button
                    {...nextButtonProps}
                    ref={nextButtonRef}
                    variant="outline"
                    className={cn('absolute right-1 h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100')}
                >
                    <ChevronRightIcon className="h-4 w-4"/>
                </Button>
            </div>
            <CalendarGrid state={state}/>
        </div>
    );
}

interface CalendarGridProps {
    state: CalendarState;
}

function CalendarGrid({state, ...props}: CalendarGridProps) {
    const {locale} = useLocale();
    const {gridProps, headerProps, weekDays} = useCalendarGrid(props, state);

    // Get the number of weeks in the month so we can render the proper number of rows.
    const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

    return (
        <table {...gridProps} className={cn(gridProps.className, 'w-full border-collapse space-y-1 text-textdark')}>
            <thead {...headerProps}>
            <tr className="flex">
                {weekDays.map((day, index) => (
                    <th
                        className="w-9 rounded-md text-[0.8rem] font-normal"
                        key={index}
                    >
                        {day}
                    </th>
                ))}
            </tr>
            </thead>
            <tbody>
            {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
                <tr className="mt-2 flex w-full" key={weekIndex}>
                    {state
                        .getDatesInWeek(weekIndex)
                        .map((date, i) =>
                            date ? <CalendarCell key={i} state={state} date={date}/> : <td key={i}/>,
                        )}
                </tr>
            ))}
            </tbody>
        </table>
    );
}

interface CalendarCellProps {
    state: CalendarState;
    date: CalendarDate;
}

function CalendarCell({state, date}: CalendarCellProps) {
    const ref = React.useRef<HTMLButtonElement | null>(null);
    const {cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, formattedDate} =
        useCalendarCell({date}, state, ref);

    const isToday = useMemo(() => {
        const timezone = getLocalTimeZone();
        return _isToday(date, timezone);
    }, [date]);

    return (
        <td
            {...cellProps}
            className={cn(
                cellProps.className,
                'relative p-0 text-center text-sm focus-within:relative focus-within:z-20 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md',
            )}
        >
            <Button
                {...buttonProps}
                type="button"
                variant="ghost"
                ref={ref}
                className={cn(
                    buttonProps.className,
                    'h-9 w-9 text-textmedium',
                    isToday && 'bg-accent text-textlight',
                    isSelected &&
                    'bg-secondarytransparenter text-textlight border-secondary border',
                    isOutsideVisibleRange && 'opacity-50',
                    isDisabled && 'opacity-50',
                )}
            >
                {formattedDate}
            </Button>
        </td>
    );
}

interface DateSegmentProps {
    segment: IDateSegment;
    state: DateFieldState;
}

function DateSegment({segment, state}: DateSegmentProps) {
    const ref = useRef(null);

    const {
        segmentProps: {...segmentProps},
    } = useDateSegment(segment, state, ref);

    return (
        <div
            {...segmentProps}
            ref={ref}
            className={cn(
                'focus:rounded-[2px] focus:bg-secondarytransparent focus:text-textlight focus:outline-none',
                segment.type !== 'literal' && 'px-[1px]',
                segment.isPlaceholder && 'text-muted-foreground',
            )}
        >
            {segment.text}
        </div>
    );
}

function DateField(props: AriaDatePickerProps<DateValue>) {
    const ref = useRef<HTMLDivElement | null>(null);

    const {locale} = useLocale();
    const state = useDateFieldState({
        ...props,
        locale,
        createCalendar,
    });
    const {fieldProps} = useDateField(props, state, ref);

    return (
        <div
            {...fieldProps}
            ref={ref}
            className={cn(
                'inline-flex h-10 flex-1 items-center rounded-l-md border-input bg-transparent px-3 py-2 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                props.isDisabled && 'cursor-not-allowed opacity-50',
            )}
        >
            {state.segments.map((segment, i) => (
                <DateSegment key={i} segment={segment} state={state}/>
            ))}
            {state.isInvalid && <span aria-hidden="true">🚫</span>}
        </div>
    );
}

function TimeField(props: AriaTimeFieldProps<TimeValue>) {
    const ref = useRef<HTMLDivElement | null>(null);

    const {locale} = useLocale();
    const state = useTimeFieldState({
        ...props,
        locale,
    });
    const {
        fieldProps: {...fieldProps},
        labelProps,
    } = useTimeField(props, state, ref);

    return (
        <div
            {...fieldProps}
            ref={ref}
            className={cn(
                'inline-flex h-10 w-full flex-1 rounded-md border text-textmedium bg-transparent px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2',
                props.isDisabled && 'cursor-not-allowed opacity-50',
            )}
        >
            {state.segments.map((segment, i) => (
                <DateSegment key={i} segment={segment} state={state}/>
            ))}
        </div>
    );
}

const TimePicker = React.forwardRef<
    HTMLDivElement,
    Omit<TimeFieldStateOptions<TimeValue>, 'locale'>
>((props, forwardedRef) => {
    return <TimeField {...props} />;
});

TimePicker.displayName = 'TimePicker';

export type DateTimePickerRef = {
    divRef: HTMLDivElement | null;
    buttonRef: HTMLButtonElement | null;
    contentRef: HTMLDivElement | null;
    jsDate: Date | null;
    state: DatePickerState;
};

interface DateTimePickerProps {
    startJsDate?: Date | null;
    endJsDate?: Date | null;
    onJsDateChange?: (start: Date, end: Date) => void;
    showClearButton?: boolean;
}

const DateTimePickerRange = React.forwardRef<
    DateTimePickerRef,
    DatePickerStateOptions<DateValue> & {
    jsDateStart?: Date | null;
    jsDateEnd?: Date | null;
    onJsDateChange?: (start: Date, end: Date) => void;
    showClearButton?: boolean;
}
>(({jsDateStart, jsDateEnd, onJsDateChange, showClearButton = true, ...props}, ref) => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const dispatch = useDispatch()
    const [isOpened, setIsOpened] = useState(false)
    const timeRange = useSelector(timerange.selectors.getTimeRange)
    let range = timeRange.getStartEnd()
    const [jsDatetimeStart, setJsDatetimeStart] = useState(range[0]);
    const [jsDatetimeEnd, setJsDatetimeEnd] = useState(range[1]);
    const stateStart = useDatePickerState(props);
    const stateEnd = useDatePickerState(props);

    useEffect(() => {
        // Update the jsDatetimeStart and jsDatetimeEnd when the time range changes
        let range = timeRange.getStartEnd()

        setJsDatetimeStart(range[0])
        setJsDatetimeEnd(range[1])
        stateStart.setValue(toCalendarDateTime(fromDate(range[0], getLocalTimeZone())))
        stateEnd.setValue(toCalendarDateTime(fromDate(range[1], getLocalTimeZone())))
    }, [timeRange])

    useImperativeHandle(ref, () => ({
        divRef: divRef.current,
        buttonRef: buttonRef.current,
        contentRef: contentRef.current,
        jsDate: jsDatetimeStart,
        state: stateStart,
    }));

    const {
        groupProps: groupPropsStart,
        fieldProps: fieldPropsStart,
        buttonProps: _buttonPropsStart,
        dialogProps: dialogPropsStart,
        calendarProps: calendarPropsStart,
    } = useDatePicker(props, stateStart, divRef);
    const {buttonProps: buttonPropsStart} = useButton(_buttonPropsStart, buttonRef);

    useEffect(() => {
        /**
         * If user types datetime, it will be a null value until we get the correct datetime.
         * This is controlled by react-aria.
         **/
        if (stateStart.value) {
            const date = parseDateTime(stateStart.value.toString()).toDate(getLocalTimeZone());
            setJsDatetimeStart(date);
            // onJsDateChange?.(date);
        }
    }, [stateStart.value, onJsDateChange]);



    useImperativeHandle(ref, () => ({
        divRef: divRef.current,
        buttonRef: buttonRef.current,
        contentRef: contentRef.current,
        jsDate: jsDatetimeEnd,
        state: stateEnd,
    }));

    const {
        groupProps: groupPropsEnd,
        fieldProps: fieldPropsEnd,
        buttonProps: _buttonPropsEnd,
        dialogProps: dialogPropsEnd,
        calendarProps: calendarPropsEnd,
    } = useDatePicker(props, stateEnd, divRef);
    const {buttonProps: buttonPropsEnd} = useButton(_buttonPropsEnd, buttonRef);

    useEffect(() => {
        /**
         * If user types datetime, it will be a null value until we get the correct datetime.
         * This is controlled by react-aria.
         **/
        if (stateEnd.value) {
            const date = parseDateTime(stateEnd.value.toString()).toDate(getLocalTimeZone());
            setJsDatetimeEnd(date);
        }
    }, [stateEnd.value, onJsDateChange]);

    return (
        <div
            {...groupPropsStart}
            ref={divRef}
            className={cn(
                groupPropsStart.className,
                'flex items-center rounded-md ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2 bg-backgroundmedium text-textmedium',
            )}
        >
            <Popover open={isOpened} onOpenChange={() => {
                setIsOpened(!isOpened)
            }}>
                <PopoverTrigger asChild onClick={() => {
                    isOpened ? setIsOpened(false) : setIsOpened(true)
                }}>
                    <div
                        onBlur={() => {
                            if (timeRange.last) {
                                return
                            }
                            if (!jsDatetimeStart || !jsDatetimeEnd || jsDatetimeStart.getTime() >= jsDatetimeEnd.getTime()) {
                                return
                            }
                            let startEnd = timeRange.getStartEnd()
                            if (startEnd[0].getTime() === jsDatetimeStart.getTime() && startEnd[1].getTime() === jsDatetimeEnd.getTime()) {
                                return
                            }
                            dispatch(set(new TimeRange(undefined, jsDatetimeStart, jsDatetimeEnd)))
                        }}
                        className={"flex gap-2"}>
                        <div className="w-max h-6 group flex hover:cursor-pointer items-center"
                             onClick={() => {
                                 isOpened ? setIsOpened(false) : setIsOpened(true)
                             }}
                        >
                            <div
                                className="w-[42px] group-hover:border-primary group-hover:bg-backgroundlight border rounded-l items-center border-emerald-400 bg-secondarytransparenter h-full text-center text-textlight text font-medium leading-[22px]">{timeRange.renderDifference()}
                            </div>
                            {timeRange.last &&
                                <div>
                                    <div
                                        className="flex gap-2 px-2 group-hover:border-primary w-max items-center border-r border-t border-b rounded-r bg-backgroundlight h-full text-center text-textmedium text font-medium leading-[22px]">
                                        <div>
                                            {timeRange.renderTimeRange()}
                                        </div>
                                        <div>
                                            <CaretDownIcon className="w-4 h-4 text-textmedium"/>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                !timeRange.last &&
                                <div>
                                    <DateField onFocus={(e) => {
                                        setIsOpened(true)
                                    }} {...fieldPropsStart}
                                               value={toCalendarDateTime(fromDate(jsDatetimeStart || new Date(), getLocalTimeZone()))}/>
                                    <DateField onFocus={(e) => setIsOpened(true)} {...fieldPropsEnd}
                                               value={toCalendarDateTime(fromDate(jsDatetimeEnd || new Date(), getLocalTimeZone()))}/>
                                </div>
                            }
                        </div>
                        {timeRange.last &&
                            <div
                                onClick={() => {
                                    let startEnd = timeRange.getStartEnd()
                                    dispatch(set(new TimeRange(undefined, startEnd[0], startEnd[1])))
                                }}
                                className={"w-6 h-6 border hover:cursor-pointer rounded items-center flex justify-center"}>
                                <HiPause className="w-4 h-4 text-textmedium"/>
                            </div>
                        }
                        {!timeRange.last &&
                            <div
                                onClick={() => {
                                    let startEnd = timeRange.getStartEnd()
                                    dispatch(set(new TimeRange(timeRange.renderDifference(), undefined, undefined)))
                                }}
                                className={"w-6 h-6 border hover:cursor-pointer rounded items-center flex justify-center"}>
                                <IoPlaySharp className="w-4 h-4 text-textmedium"/>
                            </div>
                        }
                    </div>
                </PopoverTrigger>
                <PopoverContent data-side={"left"} data-align={"end"} ref={contentRef}
                                className="w-max bg-backgroundmedium p-4">
                    {timeRange.last && <div>
                        {[
                            {"human": "Last 5 Minutes", "last": "5m"},
                            {"human": "Last 15 Minutes", "last": "15m"},
                            {"human": "Last 30 Minutes", "last": "30m"},
                            {"human": "Last 1 Hour", "last": "1h"},
                            {"human": "Last 3 Hours", "last": "3h"},
                            {"human": "Last 6 Hours", "last": "6h"},
                            {"human": "Last 12 Hours", "last": "12h"},
                            {"human": "Last 24 Hours", "last": "24h"},
                            {"human": "Last 7 Days", "last": "7d"},
                            {"human": "Last 14 Days", "last": "14d"},
                            {"human": "Last 28 Days", "last": "28d"},
                            {"human": "Select from calendar", "last": "Select from calendar"}
                        ].map((item, index) => {
                            if (item.last === "Select from calendar") {
                                return <div key={index} onClick={() => {
                                    let startEnd = timeRange.getStartEnd();
                                    dispatch(set(new TimeRange(undefined, startEnd[0], startEnd[1])))
                                    setIsOpened(true)
                                }}
                                            className="hover:bg-primary rounded w-full text-textmedium p-2 cursor-pointer">{item.human}</div>
                            }
                            return <div key={index} onClick={() => {
                                dispatch(set(new TimeRange(item.last, undefined, undefined)))
                                setIsOpened(false)
                            }}
                                        className="hover:bg-primary rounded w-full text-textmedium p-2 cursor-pointer">{item.human}</div>
                        })}
                    </div>}
                    {!timeRange.last &&
                        <div>
                            <div className="flex gap-4 text-textmedium">
                                <div {...dialogPropsStart} className="space-y-3">
                                    <div>Start Time Period</div>
                                    <Calendar {...calendarPropsStart} />
                                    <TimeField value={stateStart.timeValue} onChange={stateStart.setTimeValue}/>
                                </div>
                                <div {...dialogPropsEnd} className="space-y-3">
                                    <div>End Time Period</div>
                                    <Calendar {...calendarPropsEnd} />
                                    <TimeField value={stateEnd.timeValue} onChange={stateEnd.setTimeValue}/>
                                </div>
                            </div>
                            <Button
                                disabled={!jsDatetimeStart || !jsDatetimeEnd || jsDatetimeStart.getTime() >= jsDatetimeEnd.getTime()}
                                onClick={() => {
                                    if (!jsDatetimeStart || !jsDatetimeEnd || jsDatetimeStart.getTime() >= jsDatetimeEnd.getTime()) {
                                        return
                                    }
                                    dispatch(set(new TimeRange(undefined, jsDatetimeStart, jsDatetimeEnd)))
                                    setIsOpened(false)
                                }}
                                className={"mt-4 w-full text-textmedium border border-primary bg-primarytransparent"}>Set
                                Time</Button>
                        </div>}
                </PopoverContent>
            </Popover>

        </div>
    );
});

const DateTimePicker = React.forwardRef<
    DateTimePickerRef,
    DatePickerStateOptions<DateValue> & {
    jsDate?: Date | null;
    onJsDateChange?: (date: Date) => void;
    showClearButton?: boolean;
}
>(({jsDate, onJsDateChange, showClearButton = true, ...props}, ref) => {
    const divRef = useRef<HTMLDivElement | null>(null);
    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);
    const [jsDatetime, setJsDatetime] = useState(jsDate || null);

    const state = useDatePickerState(props);

    useImperativeHandle(ref, () => ({
        divRef: divRef.current,
        buttonRef: buttonRef.current,
        contentRef: contentRef.current,
        jsDate: jsDatetime,
        state,
    }));
    const {
        groupProps,
        fieldProps,
        buttonProps: _buttonProps,
        dialogProps,
        calendarProps,
    } = useDatePicker(props, state, divRef);
    const {buttonProps} = useButton(_buttonProps, buttonRef);

    const currentValue = useCallback(() => {
        if (!jsDatetime) {
            return null;
        }

        const parsed = fromDate(jsDatetime, getLocalTimeZone());

        return toCalendarDateTime(parsed);

    }, [jsDatetime]);

    useEffect(() => {
        /**
         * If user types datetime, it will be a null value until we get the correct datetime.
         * This is controlled by react-aria.
         **/
        if (state.value) {
            const date = parseDateTime(state.value.toString()).toDate(getLocalTimeZone());
            setJsDatetime(date);
            onJsDateChange?.(date);
        }
    }, [state.value, onJsDateChange]);
    return (
        <div
            {...groupProps}
            ref={divRef}
            className={cn(
                groupProps.className,
                'flex items-center rounded-md border ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2',
            )}
        >
            <Popover open={props.isOpen} onOpenChange={props.onOpenChange}>
                <PopoverTrigger asChild>

                    <Button
                        {...buttonProps}
                        variant="ghost"
                        className="border-r"
                        disabled={props.isDisabled}
                        onClick={() => {
                            state.setOpen(true);
                        }}
                    >
                        <CalendarIcon className="h-5 w-5"/>
                    </Button>
                </PopoverTrigger>
                <PopoverContent ref={contentRef} className="w-full">
                    <div {...dialogProps} className="space-y-3">
                        <Calendar {...calendarProps} />
                        <TimeField value={state.timeValue} onChange={state.setTimeValue}/>
                    </div>
                </PopoverContent>
            </Popover>
            <DateField {...fieldProps} value={currentValue()}/>
            <div className={cn('-ml-2 mr-2 h-5 w-5', !showClearButton && 'hidden')}>
                <X
                    className={cn('h-5 w-5 cursor-pointer text-primary/30', !jsDatetime && 'hidden')}
                    onClick={() => setJsDatetime(null)}
                />
            </div>
        </div>
    );
});

DateTimePicker.displayName = 'DateTimePicker';

export {DateTimePicker, TimePicker, DateTimePickerRange};