import {CopyIcon, CheckIcon} from "lucide-react";
import {useState} from "react";
import {Tooltip, TooltipContent, TooltipTrigger} from "./ui/tooltip";


function CopyToClipboardButton(props: { text: string }) {
    const [isCopied, setIsCopied] = useState(false);
  const handleCopy = () => {
    navigator.clipboard.writeText(props.text);
    setIsCopied(true);
    setTimeout(() => setIsCopied(false), 2000);
  };

  return (
      <Tooltip delayDuration={10}>
          <TooltipTrigger>
              {!isCopied &&
              <CopyIcon className={"text-textdark w-4 h-4 hover:cursor-pointer hover:text-textmedium"}
                        onClick={() => handleCopy()}/>}
              {isCopied && <CheckIcon className={"text-textdark w-4 h-4"} />}
          </TooltipTrigger>
          <TooltipContent side={"right"} className={"bg-backgrounddark text-textmedium border rounded px-2 py-1"}>
                {isCopied ? "Copied!" : "Copy to clipboard"}
          </TooltipContent>
      </Tooltip>

  );
}

export default CopyToClipboardButton;